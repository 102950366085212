import axios from "axios";
import { Notification, NotificationData } from "../../constants/notifications";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

export const formatNotification: (sentToId: string, authorId: string, authorName: string, text: string) => Notification 
= (sentToId, authorId, authorName, text) => {
    const data: NotificationData = {
        author: {
            id: authorId,
            name: authorName
        },
        text,
        date: new Date().toLocaleString(),
        read: false
    }

    return {
        notificationId: '',
        sentToId,
        data
    }
}

export const addNotification = async (notification: Notification) => {
    try {
        const result = await axios.post(`${BASE_URL}/notifications/add`, notification);

        return result;
    } catch(error) {
        throw error
    }
}

export const getUserNotifications = async (userId: string) => {
    try {
        const link = `${BASE_URL}/notifications/${userId}`
        
        const result = await axios.get(link);
        //@ts-ignore
        const notifications = result?.data?.result?.Items || [];

        return notifications
    } catch (error) {
        throw error;
    }
}