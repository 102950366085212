import { yupResolver } from "@hookform/resolvers/yup";
import { addCreditUser } from '../utils/SoftPullAPI';
import { useSearchParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ACCESS_LEVEL, ApplicationTypes, UserTypes } from '../constants';
import { FunctionComponent, useState } from 'react';
import { registerAuthUser, markEmailAsVerified, confirmUser } from '../utils/Cognito';
import { stripeHelpers } from '../utils/ClientVitalsAPI';
import RegisterSubscriber from '../components/forms/ValidationScehma/RegisterSubscriberSchema';
import RegisterSubscriberForm from '../components/forms/RegisterSubscriberForm';
import convert from 'xml-js';
import Button from '../components/Button';
import axios from 'axios';

const Register: FunctionComponent = () => {
    const [params] = useSearchParams();
    const [terms, setTerms] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(RegisterSubscriber)
    });

    const invitedBy = params.get('i');
    const accountType = watch('accountType');

    const getAccessLevel = (accountType: UserTypes) => {
        switch(accountType) {
            case UserTypes.COMPANY:
                return ACCESS_LEVEL.COMPANY;

            case UserTypes.MEMBER:
                return ACCESS_LEVEL.MEMBER;

            case UserTypes.REFERRAL:
                return ACCESS_LEVEL.REFERRAL;
            
            default:
                return ACCESS_LEVEL.CLIENT;
        }
    }

    const submit: SubmitHandler<any> = async (data) => {
        const { email, password } = data;
        try {
            if (!terms) return;
            const result = await registerAuthUser(email, password);

            const userType = data.accountType === UserTypes.MEMBER ? UserTypes.MEMBER : UserTypes.COMPANY;

            data.accountType = ApplicationTypes.SUBSCRIBER;
            
            await confirmUser(email);
            await markEmailAsVerified(email);

            const subscriber = {
                userId: result?.UserSub || `${Math.random().toFixed(4)}-${Math.random().toFixed(4)}-${Math.random().toFixed(4)}-${Math.random().toFixed(4)}`.replaceAll('.', 'e'),
                userType: userType,
                userInfo: {
                    name: data.name,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                    zip: data.zip,
                    phone: data.phone,
                    email: data.email,
                    ein: data.ein
                },
                metadata: {
                    invitedBy: invitedBy,
                    accessLevel: getAccessLevel(userType)
                }
            }

            if(userType === UserTypes.MEMBER) {
                //@ts-ignore
                if(invitedBy?.length) subscriber.userInfo.companyId = invitedBy;
                //@ts-ignore
                else subscriber.userInfo.companyId = process.env.REACT_APP_SUB_ACCOUNT;
            } 
            const ipResult = await axios.get('https://api.ipify.org?format=json');
            
            const cbcResult = await addCreditUser({
                uid: subscriber.userId,
                name: subscriber.userInfo.name,
                email: subscriber.userInfo.email,
                password: data.password,
                ip: ipResult.data.ip
            });

            const result2 = convert.xml2js(cbcResult, { compact: true });
            
            //@ts-ignore
            subscriber.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];

            const customer = await stripeHelpers.addNewCustomer(subscriber);

            navigate(`/payment-information?ui=${subscriber.userId}&si=${customer?.data.id}&name=${subscriber.userInfo.name}`);
        } catch (error: any) {
            setError(error?.message || 'Failed To Register Account')
        }
    };

    return (
        <div className='relative h-screen overflow-hidden'>
            <div className={`w-[700px] bg-white rounded-lg border-primary border-2 absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]`}>
                <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        Application for Account
                    </h4>
                </div>
                {
                    error && <p className='text-danger'>{error}</p>
                }

                <form className='w-full p-8 flex flex-col justify-center align-center'>
                    <RegisterSubscriberForm 
                        register={register} 
                        setTerms={(event: any) => setTerms(event?.target?.checked)} 
                        accountType={accountType || UserTypes.MEMBER}
                        terms={terms}  
                        errors={errors} 
                    />

                    <Button
                        text={'Submit Application'}
                        styles='mt-3 w-[200px] self-center'
                        testId='submit-register'
                        onClick={handleSubmit(submit)}
                    />
                </form>

                <div className='flex flex-col'>                    
                    <Link to='/login' className='text-primary self-center mb-4'>
                        Already Have Account?
                    </Link>
                </div>
            </div>  
        </div>
    )
}

export default Register;