import {
    createContext,
    ReactNode,
    FunctionComponent,
    useState,
    useEffect
} from "react";
import { getUserNotifications } from "../utils/ClientVitalsAPI/NotificationHelpers";

export enum NotificationThemes {
    SUCCESS = 'success',
    FAIL = 'fail',
    NEUTRAL = 'neutral'
}

export type Notification = {
    message: string;
    theme: NotificationThemes;
}

export type DashboardContextType = {
    notification: Notification;
    notifications: Record<any, any>[];
    setNotifications: (data: Record <any, any>[]) => void;
    updateNotification: (data: Notification) => void;
    clearNotification: () => void;
}

export const DashboardContext = createContext<DashboardContextType>({
    notification: { message: '', theme: NotificationThemes.NEUTRAL },
    notifications: [],
    setNotifications: (data: Record <any, any>[]) => {},
    updateNotification: () => { },
    clearNotification: () => { },
});

interface IDashboardProviderProps {
    userId: string;
    children: ReactNode
}

const blankNotification = {
    message: '',
    theme: NotificationThemes.NEUTRAL
}

const DashboardProvider: FunctionComponent<IDashboardProviderProps> = ({ userId, children }) => {
    const [notification, setNotification] = useState<Notification>(blankNotification);
    const [notifications, setNotifications] = useState<Record <any, any>[]>([]);

    const clearNotification = () => setTimeout(() => setNotification(blankNotification), 5000);
    const updateNotification = (data: Notification) => {
        setNotification(data);
        clearNotification();
    }

    const fetchNotifications = async () => {
        try {
            const result = await getUserNotifications(userId);

            setNotifications(result);
        } catch(error) {
            throw error;
        }
    }

    useEffect(() => {
        if(userId) fetchNotifications();
    }, [])

    const provider: DashboardContextType = {
        notification,
        notifications,
        setNotifications,
        updateNotification,
        clearNotification,
    }

    return (
        <DashboardContext.Provider value={provider}>
            {children}
        </DashboardContext.Provider>
    )
}

export default DashboardProvider;


const dummyData = [
    {
        notificationId: '1',
        sentToId: '1',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Client Registered',
            date: '12/17/2024',
            read: false,
        }
    },
    {
        notificationId: '2',
        sentToId: '2',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Credit Form Submitted',
            date: '12/17/2024',
            read: true,
        }
    },
    {
        notificationId: '4',
        sentToId: '4',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Credit Form Submitted',
            date: '12/17/2024',
            read: true,
        }
    },
    {
        notificationId: '3',
        sentToId: '3',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Client Registered',
            date: '12/17/2024',
            read: false,
        }
    },
    {
        notificationId: '1',
        sentToId: '1',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Client Registered',
            date: '12/17/2024',
            read: false,
        }
    },
    {
        notificationId: '2',
        sentToId: '2',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Client Registered',
            date: '12/17/2024',
            read: true,
        }
    },
    {
        notificationId: '4',
        sentToId: '4',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Client Registered',
            date: '12/17/2024',
            read: true,
        }
    },
    {
        notificationId: '3',
        sentToId: '3',
        data:     { 
            author: 'Bernard Johnson',
            text: 'Client Registered',
            date: '12/17/2024',
            read: false,
        }
    },
];