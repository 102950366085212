import { FunctionComponent } from "react";
import { Routes, Route } from "react-router-dom";
import Partners from "../pages/partners/Partners";
import PartnersId from "../pages/partners/PartnersId";

const PartnersRoute: FunctionComponent = () => (
    <Routes>
        <Route path='/' element={<Partners />}/>
        <Route path=':id' element={<PartnersId />}/>
    </Routes>
)

export default PartnersRoute