import * as yup from "yup";

const RegisterClientSchema = yup.object().shape({
    name: yup
        .string()
        .required('Please Enter Name'),
    address: yup
        .string()
        .required('Please Enter Address'),
    city: yup
        .string()
        .required('Please Enter City'),
    state: yup
        .string()
        .required('Please Enter State'),
    zip: yup
        .string()
        .required('Please Enter Postal Code'),
    phone: yup
        .string()
        .min(10, 'Please Enter 10 Digit Phone Number')
        .required('Please Enter Phone Number'),
    email: yup
        .string()
        .email("Please enter a valid email!")
        .required("Email is required!"),
    password: yup
        .string()
        .min(8, "Password must be longer than 8 characters")
        .required("Password is required"),
    dob: yup
        .string()
        .required('Please enter birthday MM/DD/YYYY'),
    gender: yup
        .string()
        .required('Please select gender'),
    deniedMortgage: yup
        .string()
        .required('Please Select An Option For Each Question'),
    deniedRental: yup
        .string()
        .required('Please Select An Option For Each Question'),
    defaulted: yup
        .string()
        .required('Please Select An Option For Each Question'),
    maritalStatus: yup
        .string()
        .required('Please Select An Option For Each Question'),
    // agreedToTerms: yup.boolean(),
});

export default RegisterClientSchema;