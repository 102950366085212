import { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import SquareTile from "../../components/tiles/SquareTile";
import Loading from "../../components/Loading";
import referralHelpers from "../../utils/ClientVitalsAPI/ReferralHelpers";
import useClients from "../../hooks/useClients";
import { ReactComponent as EditIcon } from '../../assets/edit-filled.svg';

const PartnersId: FunctionComponent = () => {
    const { id } = useParams<{ id: string }>();
    const [partner, setPartner] = useState<any>(null);
    const { clients } = useClients();

    const clientsToRender = clients?.filter((client) => client?.metadata?.invitedBy === id)

    const fetchReferralAccounts = async () => {
        try {
            const result = await referralHelpers.getReferralAccounts();
            const selectedPartner = result.find((part: any) => part.userId === id)
            setPartner(selectedPartner)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!partner) fetchReferralAccounts();
    }, [partner])


    if (!partner) {
        return <Loading />
    }

    return (
        <DashboardPageContainer>
            <PageTitle title={`${partner.userInfo.name}`} />

            <div className="grid grid-cols-2 gap-20">
                <SquareTile >
                    {
                        <>
                            <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
                                <h4 className="text-2xl">Partner Information</h4>
                                <p className="mt-2">
                                    <EditIcon
                                        height={25}
                                        width={25}
                                        // onClick={toggleForm}
                                        className="cursor-pointer"
                                        data-testid='edit-client-icon'
                                    />
                                </p>
                            </div>
                            <div className="text-md flex flex-col align-center">
                                <p className="mb-3"><span className="font-medium">Name:</span> {partner?.userInfo?.name}</p>
                                <p className="mb-3"><span className="font-medium">Email:</span> {partner?.userInfo?.email}</p>
                                <p className="mb-3"><span className="font-medium">Phone:</span> {partner?.userInfo?.phone}</p>

                                <p className="mb-3"><span className="font-medium">Address:</span> {partner?.userInfo?.address}</p>
                                <p className="mb-3"><span className="font-medium">City:</span> {partner?.userInfo?.city}</p>
                                <p className="mb-3"><span className="font-medium">State:</span> {partner?.userInfo?.state}</p>
                                <p className="mb-3"><span className="font-medium">Postal Code:</span> {partner?.metadata?.zip}</p>

                            </div>
                        </>
                    }
                </SquareTile>
                <SquareTile>
                    <div className="w-full border-primary border border-primary border-t-0 border-l-0 border-r-0 flex justify-between align-center mb-4">
                        <h4 className="text-2xl">Referred Clients</h4>
                    </div >
                    {
                        clientsToRender?.map(clients => (
                            <div className="flex items-center justify-around border border-primary border-t-0 border-r-0 mb-3">
                                <p><span className="font-medium">Name:</span> {clients?.userInfo?.name}</p>
                                <p><span className="font-medium">Referred Date:</span> {clients?.metadata?.createdAt}</p>
                                <p><span className="font-medium">Status:</span> {clients?.userInfo?.name}</p>
                            </div>
                        ))
                    }
                </SquareTile>
            </div>
        </DashboardPageContainer>
    );
}

export default PartnersId