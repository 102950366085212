import { FunctionComponent } from "react";

interface IRadialGradientProps {
  top?: string;
  left?: string;
  height?: string;
  width?: string;
  transformCenter?: boolean;
}

const RadialGradient: FunctionComponent<IRadialGradientProps> = ({
  top = "0",
  left = "0",
  height = "600px",
  width = "600px",
  transformCenter = false,
}) => {
  return (
    <div
      className={`absolute radial-gradient z-negative rounded-full ${
        transformCenter ? "transform -translate-x-1/2 -translate-y-1/2" : ""
      }`}
      style={{
        top,
        left,
        width,
        height,
      }}
    />
  );
};

export default RadialGradient;


