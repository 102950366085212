import { FunctionComponent, useState } from "react";
import Button, { BUTTON_THEMES } from "../../Button";
import Stepper from "../../stepper";
import ClientInfoForm from "./ClientInfoForm";
import HousingForm from "./HousingForm";
import MedicalDebtForm from "./MedicalDebtForm";
import MaritalForm from "./MaritalForm";
import FamilialForm from "./FamilialForm";
import EmploymentForm from "./EmploymentForm";
import GeneralInfoForm from "./GeneralInfoForm";
import TaxForm from "./TaxForm";
import { UseFormRegister } from "react-hook-form";


interface IClientOnboardingProps {
    register: UseFormRegister<any>;
    submit: Function;
    gender: string;
    maritalStatus: string;
    errors?: any;
}

const ClientOnboarding: FunctionComponent<IClientOnboardingProps> = ({register, gender, maritalStatus, errors, submit}) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [totalSteps, setTotalSteps] = useState<number>(8);

    const goNext = () => setCurrentStep(currentStep + 1);
    const goBack = () => setCurrentStep(currentStep - 1);

    const goNextSwitch = () => {
        if(currentStep < totalSteps) {
            return goNext;
        } else {
            return () => console.log('submit');
        }
   }

   const formSwitch = () => {
      switch(currentStep) {
        case 1:
            return <ClientInfoForm register={register} errors={errors} gender={gender} />

        case 2:
            return <HousingForm register={register} />

        case 3:
            return <MedicalDebtForm register={register} />

        case 4: 
            return <MaritalForm register={register} maritalStatus={maritalStatus} />

        case 5:
            return <FamilialForm register={register} />

        case 6:
            return <EmploymentForm register={register} />

        case 7:
            return <GeneralInfoForm register={register} />

        case 8:
            return <TaxForm register={register} />
      }
   } 

    return (
        <div className='w-full p-8 flex flex-col justify-center align-center'>
            <div className="w-full flex justify-center items-center">
                <Stepper currentStep={currentStep} totalSteps={totalSteps} />
            </div>

            <div className="h-[470px]">
                {formSwitch()}
            </div>

            <div className="w-full flex justify-between items-end">
                <Button text="Back" disabled={currentStep === 1} theme={BUTTON_THEMES.INVERSE} onClick={goBack} />
                {
                    //@ts-ignore
                    currentStep === totalSteps ? <Button text="Submit" disabled={currentStep !== totalSteps} onClick={submit} />
                        : <Button text="Next" disabled={currentStep === totalSteps} onClick={goNextSwitch()} />
                }
            </div>
        </div>
    )
}

export default ClientOnboarding;