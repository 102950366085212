import { FunctionComponent, useEffect, useState } from "react";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import Loading from "../../components/Loading";
import Table from "../../components/table";

const Requests: FunctionComponent = () => {
    const [loading, setLoading] = useState<boolean>(true);

    const headerList: string[] = ['checkbox', 'member', 'request sent', 'request started', 'request completed', 'status', ''];
    const records: any[] = [];

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <DashboardPageContainer>
            <PageTitle title="Requested Actions" />

            {
                loading ? <Loading />
                        : <Table 
                            columnHeaders={headerList}
                            records={records}
                            tableActions={[]}
                            customButton={<></>}
                            linkBase="/dashboard/requests"
                        />
            }
        </DashboardPageContainer>
    )
} 

export default Requests;