import { FunctionComponent, useState, useEffect } from "react";
import { useUser } from "../../hooks/useUser";
import {
    Chat,
    Channel,
    ChannelList,
    Window,
    ChannelHeader,
    MessageList,
    MessageInput,
    Thread,
    useCreateChatClient,
  } from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";


interface IChatScreenProps {

}

const ChatScreen: FunctionComponent<IChatScreenProps> = ({}) => {
    const [channel, setChannel] = useState<any>();
    const apiKey = process.env.REACT_APP_STREAM_ACCESS_KEY || "";
    const { user, chatToken } = useUser();
    //@ts-ignore
    const userManager = user?.userInfo?.memberId?.length > 1 ? user?.userInfo?.memberId : user?.userInfo?.companyId
    const filters = { members: { $in: [user?.userId, userManager] }, type: "messaging" };
    const options = { presence: true, state: true };
    const sort = { last_message_at: -1 };

    const chatClient = useCreateChatClient({
        apiKey,
        tokenOrProvider: chatToken,
        userData: { id: user?.userId },
    });

    const fetchChannels = async (chatClient: any) => {
        console.log([user?.userId, userManager])
        const channelQueryResponse = await chatClient.queryChannels(
            filters
        );
        if(channelQueryResponse[0]) setChannel(channelQueryResponse[0])
        else {
            const newChannel = chatClient.channel("messaging", {
                name: `${user.userInfo.name} & ${userManager} `,
                members: [user?.userId, userManager]
            });

            setChannel(newChannel)
        }
    }

    useEffect(() => {
        if(chatClient && !channel) fetchChannels(chatClient);
    }, [chatClient])

    if (!chatClient) return <div>Loading...</div>;

    console.log({user, chatToken, userManager})
    return (
        <div className="min-h-screen">
            <Chat 
                client={chatClient}
                customClasses={{
                    channelList: "max-h-[300px]",
                    channel: "max-h-[300px]",
                }}
            >
                <ChannelList filters={filters} options={options} />
                <Channel channel={channel}>
                <Window>
                    <ChannelHeader />
                    <MessageList />
                    <MessageInput />
                </Window>
                <Thread />
                </Channel>
            </Chat>
        </div>
    )
} 

export default ChatScreen;