import { useState, useEffect } from 'react';
import { companyHelpers, subscriptionHelpers } from '../utils/ClientVitalsAPI';

const useCompanies = (user_id: string) => {
    const [companies, setCompanies] = useState<any[]>([]);
    const [applications, setApplications] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchCompanies = async (id: string) => {
        if (!loading) setLoading(true);
        try {
            const fetchedCompanies = await companyHelpers.getCompanies();

            setCompanies(fetchedCompanies);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getCompanyById = (id: string) => {
        return companies.find(({ userId }) => userId === id);
    }

    const getApplicationById = (id: string) => {
        return applications.find(({ applicationId }) => applicationId === id);
    }

    useEffect(() => {
        if(user_id) {
            fetchCompanies(user_id);
        }
    }, [user_id])

    return { 
        companies, 
        applications, 
        loading, 
        error, 
        fetchCompanies, 
        getCompanyById, 
        getApplicationById
    }
}

export default useCompanies;