import { FunctionComponent, useState } from 'react';
import { DASHBOARD_LINKS, ACCESS_LEVEL } from '../../constants';
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg';
import { ReactComponent as HamburgerIcon } from '../../assets/hamburger-icon.svg';
import { useUser } from '../../hooks/useUser';
import { ReactComponent as Logo } from '../../assets/logo-white.svg';
import NavigationStateIcon from './NavigationStateIcon';
import Loading from '../Loading';

const extractLinksToRender = (accessLevel: ACCESS_LEVEL, open: boolean, logout: () => void) => {
    if (accessLevel === ACCESS_LEVEL.OWNER) {
        return [
            DASHBOARD_LINKS.CLIENTS(open),
            DASHBOARD_LINKS.MEMBERS(open),
            DASHBOARD_LINKS.COMPANIES(open),
            DASHBOARD_LINKS.PARTNERS(open),
            DASHBOARD_LINKS.CVA(open),
            DASHBOARD_LINKS.CLIENT_APPLICATIONS(open),
            DASHBOARD_LINKS.BILLING(open),
            DASHBOARD_LINKS.UPLOAD(open),
            DASHBOARD_LINKS.ACCOUNT(open),
            DASHBOARD_LINKS.NOTIFICATIONS(open),
            DASHBOARD_LINKS.SUPPORT(open),
            DASHBOARD_LINKS.LOGOUT(open, logout),
        ]
    } else if(accessLevel === ACCESS_LEVEL.CVA) {
        return [
            DASHBOARD_LINKS.CLIENTS(open),
            DASHBOARD_LINKS.MEMBERS(open),
            DASHBOARD_LINKS.COMPANIES(open),
            DASHBOARD_LINKS.PARTNERS(open),
            DASHBOARD_LINKS.CLIENT_APPLICATIONS(open),
            DASHBOARD_LINKS.BILLING(open),
            DASHBOARD_LINKS.UPLOAD(open),
            DASHBOARD_LINKS.SUPPORT(open),
            DASHBOARD_LINKS.ACCOUNT(open),
            DASHBOARD_LINKS.LOGOUT(open, logout),
            DASHBOARD_LINKS.NOTIFICATIONS(open)
        ]
    } else if(accessLevel === ACCESS_LEVEL.COMPANY) {
        return [
            DASHBOARD_LINKS.CLIENTS(open),
            DASHBOARD_LINKS.MEMBERS(open),
            DASHBOARD_LINKS.CLIENT_APPLICATIONS(open),
            DASHBOARD_LINKS.BILLING(open),
            DASHBOARD_LINKS.UPLOAD(open),
            DASHBOARD_LINKS.ACCOUNT(open),
            DASHBOARD_LINKS.LOGOUT(open, logout),
            DASHBOARD_LINKS.NOTIFICATIONS(open)
        ]
    } else if(accessLevel === ACCESS_LEVEL.MEMBER) {
        return [
            DASHBOARD_LINKS.CLIENTS(open),
            DASHBOARD_LINKS.CLIENT_APPLICATIONS(open),
            DASHBOARD_LINKS.BILLING(open),
            DASHBOARD_LINKS.UPLOAD(open),
            DASHBOARD_LINKS.ACCOUNT(open),
            DASHBOARD_LINKS.LOGOUT(open, logout),
            DASHBOARD_LINKS.NOTIFICATIONS(open)
        ]
    } else {
        return [
            DASHBOARD_LINKS.REQUESTS(open),
            DASHBOARD_LINKS.CHAT(open),
            DASHBOARD_LINKS.BILLING(open),
            DASHBOARD_LINKS.ACCOUNT(open),
            DASHBOARD_LINKS.LOGOUT(open, logout),
            DASHBOARD_LINKS.NOTIFICATIONS(open)
        ]
    }
}

const SideNavigation: FunctionComponent = () => {    
    const [open, setOpen] = useState<boolean>(true);
    const { user, logout } = useUser();

    if(!user) return <Loading />

    const accessLevel = user?.metadata?.accessLevel;
 
    const linksToRender = extractLinksToRender(accessLevel, open, logout);

    return (
        <nav
            className={`relative flex flex-col h-fill bg-primary text-white uppercase text-sm ${open ? 'w-[230px] pl-8' : 'w-[55px] items-center'}`}
            data-cy='side-navigation'
        >

            <div className={`flex justify-between align-start ${open ? 'flex-row' : 'flex-col'}`}>
                <Logo className={open ? 'pl-3' : 'pl-0'} height={open ? '120px' : '25px'} width={open ? '120px' : '25px'} />
                <NavigationStateIcon 
                    Icon={open ? CloseIcon : HamburgerIcon} 
                    openState={open} 
                    toggleOpenState={setOpen} 
                />
            </div>
            <ul data-cy='navigation-links-list' className={`mt-2 ${!open && 'w-[55px] pl-4 mt-4'}`}>
            {
                linksToRender.map((link, index) => <li key={index} className='mb-4'>{link}</li>)
            }
            </ul>
        </nav>
    )
}

export default SideNavigation;