import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import PageTitle from "../../PageTitle";
import RadioGroup from "../../inputs/RadioGroup";


interface IMedicalDebtFormProps {
    register: UseFormRegister<any>;
    errors?: any;
}

const MedicalDebtForm: FunctionComponent<IMedicalDebtFormProps> = ({ register, errors }) => {
    const options = [
        {label: 'Yes', id: 'true'},
        {label: 'No', id: 'false'},
    ];

    return (
        <div className="py-10">
            <PageTitle title="Medical Debt Information" />

            <div className="grid grid-cols-3 gap-2">
                <RadioGroup
                    question="Are you without medical benefits?"
                    name="noMedicalInsurance"
                    register={register}
                    options={options}
                />
            </div>
        </div>
    )
}

export default MedicalDebtForm;