import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import ClientRoutes from './ClientRoutes';
import MemberRoutes from './MemberRoutes';
import CompanyRoutes from './CompanyRoutes';
import SideNavigation from '../components/sideNavigation';
import DashboardProvider from '../context/DashboardContext';
import Upload from '../pages/Upload';
import ManagerRoutes from '../routes/ManagerRoutes';
import Account from '../pages/Account';
import Billing from '../pages/Billing';
import PartnersRoutes from './PartnerRoutes';
import SupportTickets from '../pages/support/SupportTickets';
import Requests from '../pages/requests/Requests';
import ChatScreen from '../pages/chat';
import { useUser } from '../hooks/useUser';

const DashboardRoutes: FunctionComponent = () => {
    const { user } = useUser()

    return (
        <DashboardProvider userId={user?.userId}>
                <section className='flex flex-row w-vw h-content'>
                    <SideNavigation />
                    <Routes>
                        <Route path='clients/*' element={<ClientRoutes />} />
                        <Route path='members/*' element={<MemberRoutes />} />
                        <Route path='companies/*' element={<CompanyRoutes />} />
                        <Route path='managers/*' element={<ManagerRoutes />} />
                        <Route path='partners/*' element={<PartnersRoutes />} />
                        <Route path='requests/*' element={<Requests />} />
                        <Route path='chat/*' element={<ChatScreen />} />
                        <Route path='upload' element={<Upload />} />
                        <Route path='billing' element={<Billing />} />
                        <Route path='account' element={<Account />} />
                        <Route path='support' element={<SupportTickets />} />
                    </Routes>
                </section>
        </DashboardProvider>
    );
}

export default DashboardRoutes;