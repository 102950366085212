import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput } from "../../inputs";
import { clientHelpers, creditHelpers, memberHelpers, sendgridHelpers} from "../../../utils/ClientVitalsAPI";
import { Client } from "../../../constants/clients";
import DashboardPageContainer from "../../DashboardPageContainer";
import PageTitle from "../../PageTitle";
import Button from "../../Button";
import PullRequestSchema from "../ValidationScehma/PullRequestSchema";
import { IPullRequestForm } from "../../../constants/credit";
import { Member } from "../../../constants/members";
import { useDashboard } from "../../../hooks/useDashboard";
import { NotificationThemes } from "../../../context/DashboardContext";
import CoApplicantForm from "./CoApplicantForm";
import { AUTO_NOTIFICATIONS } from "../../../constants/notifications";
import { addNotification, formatNotification } from "../../../utils/ClientVitalsAPI/NotificationHelpers";

const PullRequestForm: FunctionComponent = () => {
    const { id } = useParams();
    const { updateNotification } = useDashboard();
    const [params] = useSearchParams();
    const [client, setClient] = useState<Client | null>(null);
    const [memberData, setMemberData] = useState<Member | undefined>();
    const [error, setError] = useState<unknown>();

    const navigate = useNavigate();
    const company = params.get('i') || '';
    const member = params.get('e') || '';
    const applicantCount = params.get('ac');
    const coapplicants = Number(applicantCount) - 1 || 0;

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: client?.userInfo.name,
            email: client?.userInfo.email,
            companyId: company || '',
            memberId: member || memberData?.userId || 'test',
            dob: client?.userInfo.dob,
            address: client?.userInfo.address,
            city: client?.userInfo.city,
            state: client?.userInfo.state,
            zip: client?.userInfo.zip,
            phone: client?.userInfo.phone,
        },
        resolver: yupResolver(PullRequestSchema)
    });

    const submit = async (values: IPullRequestForm) => {
        if(!client) return;

        try {
            const newApplication = creditHelpers.formatPullRequestApplication(values)
            
            const result = await creditHelpers.addPullApplication(newApplication);
            
            //@ts-ignore
            await sendgridHelpers.sendMemberPullEmail({name: memberData?.userInfo.name, email: memberData?.userInfo.email})
           
            const newClientNotification = formatNotification(
                member ? member : company, 
                client?.userId,
                client?.userInfo.name,
                AUTO_NOTIFICATIONS.CREDIT_FORM
            );

            await addNotification(newClientNotification);

            navigate('/submission');
        } catch (error) {
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Submit Form'
            });
        }
    }

    const generateCoApplicantForms = (coapplicants: number) => {
        const forms = [];

        for(let i = coapplicants; i > 0; i--) {
            forms.push(
                <CoApplicantForm key={`coapplicant-${i}`} applicantNumber={i} register={register} />
            )
        }

        return (
            <>
                {forms.reverse().map(form => form)}
            </>
        )
    }

    const fetchClient = async (id: string) => {
        try {
            const result = await clientHelpers.fetchClientById(id);
            setClient(result);
        } catch (error) {
            setError(error);
        }
    }

    const fetchMember = async (id: string) => {
        try {
            const result = await memberHelpers.fetchMemberById(id);
       
            setMemberData(result);
        } catch (error) {
            setError(error);
        }
    }

    useEffect(() => {
        if (!client && id) fetchClient(id);
        if (!memberData && member) fetchMember(member);
    }, [])

    if(!id || error || !company || !member) return <p>ID Error</p>
    if(!client) return <p>Loading</p>

    return (
        <DashboardPageContainer>
            <PageTitle title="Complete Form to Allow Credit Pull" />

            <form>
                <div className="max-w-screen grid grid-cols-3 gap-2">
                    <TextInput 
                        id='name'
                        label='name'
                        placeholder='Name...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.name?.message}
                    />

                    <TextInput 
                        id='password'
                        label='password'
                        placeholder='Password...'
                        register={register}
                        containerStyles='my-0 p-0'
                        password
                        error={errors.password?.message}
                    />

                    <TextInput 
                        id='email'
                        label='email'
                        placeholder='Email...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.email?.message}
                    />

                    <TextInput 
                        id='address'
                        label='address'
                        placeholder='Address...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.address?.message}
                    />

                    <TextInput 
                        id='city'
                        label='city'
                        placeholder='City...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.city?.message}
                    />

                    <TextInput 
                        id='state'
                        label='state'
                        placeholder='State...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.state?.message}
                    />

                    <TextInput 
                        id='zip'
                        label='zip'
                        placeholder='Postal Code...'
                        register={register}
                        error={errors.zip?.message}
                    />

                    <TextInput 
                        id='dob'
                        label='dob'
                        placeholder='MM/DD/YYYY'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.dob?.message}
                    />

                    <TextInput 
                        id='phone'
                        label='Phone'
                        placeholder='Phone...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.phone?.message}
                    />

                    <TextInput 
                        id='ssn'
                        label='ssn'
                        placeholder='Ssn...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.ssn?.message}
                    />

                    <TextInput 
                        id='consent'
                        label='consent'
                        placeholder='I, name, hereby give my consent to have my credit pulled through ClientVitals Solutions...'
                        register={register}
                        containerStyles='my-0 p-0'
                        error={errors.ssn?.message}
                    />
                </div>

                {generateCoApplicantForms(Number(coapplicants))}
            </form>
            <div className='max-w-screen flex justify-center align-center'>
                <Button text='Submit' onClick={handleSubmit(submit)} styles='w-[300px] mt-12' />
            </div>
        </DashboardPageContainer>
    )
}

export default PullRequestForm;