import { FunctionComponent } from "react";
import { TextInput } from "../../inputs";
import { UseFormRegister } from "react-hook-form";
import PageTitle from "../../PageTitle";
import Dropdown from "../../inputs/Dropdown";

interface IClientInfoFormProps {
    register: UseFormRegister<any>;
    gender: string;
    errors?: any;
}

const ClientInfoForm: FunctionComponent<IClientInfoFormProps> = ({register, gender, errors}) => {

    return (
        <div className="py-10">
            <PageTitle title="Account Information" />

            <div className="grid grid-cols-3 gap-2">
                <TextInput
                    label='Name'
                    id='name'
                    placeholder='Full name...'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.name?.message}
                />

                <TextInput
                    label='dob'
                    id='dob'
                    placeholder='MM/DD/YYYY'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.dob?.message}
                />

                <Dropdown 
                    id="gender"
                    register={register}
                    onChange={() => {}}
                    currentValue={gender}
                    options={[
                        {
                            value: 'MALE',
                            label: 'He/Him'
                        },
                        {
                            value: 'FEMALE',
                            label: 'She/Her'
                        },
                        {
                            value: 'THEY',
                            label: 'They/Them'
                        },
                        {
                            value: 'OTHER',
                            label: 'Other'
                        }
                    ]}
                />

                <TextInput
                    label='Email'
                    id='email'
                    placeholder='Enter email address...'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.email?.message}
                />

                <TextInput
                    label='Password'
                    id='password'
                    placeholder='Enter password...'
                    containerStyles='w-[45%]'
                    register={register}
                    password
                    error={errors?.password?.message}
                />

                <TextInput
                    label='Phone'
                    id='phone'
                    placeholder='Enter phone number...'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.phone?.message}
                />

                <TextInput
                    label='Address'
                    id='address'
                    placeholder='Enter street address...'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.address?.message}
                />

                <TextInput
                    label='City'
                    id='city'
                    placeholder='Enter city...'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.city?.message}
                />

                <TextInput
                    label='State'
                    id='state'
                    placeholder='Enter state abbreviation...'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.state?.message}
                />
                
                <TextInput
                    label='Zip'
                    id='zip'
                    placeholder='Enter zip code...'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.zip?.message}
                />
            </div>
        </div>
    )
}

export default ClientInfoForm;