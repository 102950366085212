import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import PageTitle from "../../PageTitle";
import RadioGroup from "../../inputs/RadioGroup";
import { TextInput } from "../../inputs";

interface ITaxFormProps {
    register: UseFormRegister<any>;
    errors?: any;
}

const TaxForm: FunctionComponent<ITaxFormProps> = ({ register, errors }) => {
    const options = [
        {label: 'Yes', id: 'true'},
        {label: 'No', id: 'false'},
    ];

    return (
        <div className="py-10">
            <PageTitle title="Tax & Bankruptcy Information" />

            <div className="grid grid-cols-3 gap-4">
                <RadioGroup
                    question="Are you in danger of declaring bankruptcy?"
                    name="declaringBankruptcy"
                    register={register}
                    options={options}

                />

                <RadioGroup
                    question="If yes, is it because of tax debt?"
                    name="taxDebt"
                    register={register}
                    options={options}
                />

                <TextInput
                    label="Cause of Bankruptcy"
                    id='bankruptcyCause'
                    containerStyles='w-[45%]'
                    register={register}
                    error={errors?.bankruptcyCause?.message}
                    placeholder="If no, what is cause of the bankruptcy?"
                />
            </div>
        </div>
    )
}

export default TaxForm;