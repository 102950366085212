import { useState, FunctionComponent, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { ACCESS_LEVEL, CARD_ELEMENT_OPTIONS, UserTypes } from "../constants/enums";
import { stripeHelpers, userHelpers } from "../utils/ClientVitalsAPI";
import RadialGradient from "../components/RadialGradient";
import Button from "../components/Button";
import { PRODUCT_LIST_PROD, PRODUCT_LIST_STAGE } from "../constants/enums";
import { Member } from "../constants/members";
import { Company } from "../constants/companies";
import { TextInput } from "../components/inputs";

const productList = process.env.REACT_APP_ENV === 'prod' ? PRODUCT_LIST_PROD : PRODUCT_LIST_STAGE;

const PaymentClient: FunctionComponent = () => {
     const [_, setUser] = useState<Company | Member>({
        userType: UserTypes.COMPANY,
        userId: '',
        userInfo: {name: '', address: '', city: '', state: '', zip: '', email: '', country: '', phone: '', companyId: '',},
        metadata: {createdAt: '', updatedAt: '', firstLogin: true, isAdmin: false, accessLevel: ACCESS_LEVEL.CLIENT}
    });

    
    
     const [discountCode, setDiscountCode] = useState<string>('');
     const [isProcessing, setIsProcessing] = useState<boolean>(false);
     const [error, setError] = useState<any>(null);
     const [paid, setPaid] = useState<boolean>(false);
     const [params] = useSearchParams();
     const stripe = useStripe();
     const elements = useElements();
     const navigate = useNavigate()

     const userId = params.get('ui');
     const stripeId = params.get('si');
     const name = params.get('name');

    const fetchUser = async () => {
        if(!userId) return;

        try {
            const user = await userHelpers.getUserById(userId);

            setUser(user);
        } catch(error) {
            console.log(error);
        }
    }


     const setupPaymentMethod = async () => {
        if (!elements || !stripe || !stripeId || !name) return;
        setIsProcessing(true);

        try {
        const card = elements.getElement(CardElement);
        if (!card) throw new Error('Failed to fetch card');

        const { error: pmError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: { name },
        });

        if (paymentMethod) {
            const intent = await stripeHelpers.setupStripeIntent(stripeId);

            const { error, setupIntent } = await stripe.confirmCardSetup(intent.result.client_secret, {
            payment_method: paymentMethod.id,
            });

            await stripeHelpers.addNewPaymentMethod({ customerId: stripeId, paymentMethodId: paymentMethod.id });

            // Proceed to charge after saving the card
           await payForAppointment(paymentMethod.id, stripeId, productList.FIRST_APPOINTMENT);

           setPaid(true);
        }
        } catch (error: any) {
            setError(error || 'Failed To Add Payment Method');
            setIsProcessing(false);
        }
     }

     const payForAppointment = async (paymentMethodId: string, stripeId: string, productId: PRODUCT_LIST_PROD | PRODUCT_LIST_STAGE) => {
        if(!paymentMethodId || !stripe || !stripeId) return {result: false};

        try {      
            const result = await stripeHelpers.chargeCustomer(stripeId, paymentMethodId, productId, discountCode)

          } catch (error: any) {
            console.log(error);
            setError(error?.message);
            return {
                result: false
            }
          }  
     }

     useEffect(() => {
        fetchUser()
     }, []);

     return (
        <div className='relative h-screen overflow-hidden'>
            <RadialGradient top='0' left='10%' height='600px' width='600px' />
            <div className="flex justify-center align-center mt-12">
                <div className={`w-[750px] mr-10 bg-white rounded-lg border-primary border-2`}>
                    <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                        <h4>
                            Book an Appointment
                        </h4>
                    </div>

                    <form className='w-full p-8 flex flex-col justify-center align-center'>
                        <div className="pt-10 px-7 mb-4 flex flex-col">
                            <p className="mb-8 text-gray-700 text-lg font-bold">Card Information:</p>
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                        </div>

                        <div className="p-8">
                            <div className="flex align-end justify-between mb-4 text-lg">
                                <p className="font-semibold">Pre-Qualification Appointment (NEATT):</p>
                                
                                <p className="font-normal">$200</p>
                            </div>

                            
                            <TextInput 
                                id="couponCode" 
                                label="Coupon Code" 
                                placeholder="Coupon Code..." 
                                onChange={(value: string) => setDiscountCode(value)}
                            />

                            <div className="flex align-end justify-between mb-4 text-lg">
                                <p className="font-semibold">Total:</p>
                                <p className="font-normal">{discountCode?.toUpperCase() === 'MLA43FPV' ? '$150' : '$200'}</p>
                            </div>
                        </div>
                        
                        {
                            paid ? (
                                <>
                                    <a className="mx-auto p-4 rounded-md text-primary cursor w-fit px-4 border border-2 border-primary" href="https://calendly.com/jonathan-goffe-vermontconsumercredit/45min" target="_blank">
                                        View Calendar & Book Appointment
                                    </a>

                                    <p className="mt-10">Once appointment has been booked in a separate window, you can navigate to the <span onClick={() => navigate('/login')} className="text-primary underline cursor-pointer">login page</span></p>
                                </>
                            ) : (
                                <Button 
                                    text={isProcessing ? 'Processing...' : 'Submit Payment'} 
                                    type="submit" 
                                    disabled={!stripe || isProcessing} 
                                    styles="mx-auto mt-8 w-fit px-4"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setupPaymentMethod()
                                    }}
                                />
                            )
                        }

                    </form>
                </div>  
            </div>
        </div>
     )
}

export default PaymentClient;