import { yupResolver } from "@hookform/resolvers/yup";
import { addCreditUser } from '../utils/SoftPullAPI';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ACCESS_LEVEL, UserTypes } from "../constants";
import { FunctionComponent, useState } from 'react';
import { registerAuthUser, markEmailAsVerified, confirmUser } from '../utils/Cognito';
import { stripeHelpers, clientHelpers } from '../utils/ClientVitalsAPI';
import RegisterClientSchema from "../components/forms/ValidationScehma/RegisterClientSchema";
import convert from 'xml-js';
import axios from 'axios';
import ClientOnboarding from "../components/forms/ClientOnboarding";

const RegisterClientAccount: FunctionComponent = () => {
   const [params] = useSearchParams();
   const [terms, setTerms] = useState<boolean>(false);
   const [ error, setError] = useState<string | null>(null);

   const navigate = useNavigate();
   const {
        register,
        handleSubmit,
        watch,
        getValues,
        formState: { errors }
   } = useForm ({
    resolver: yupResolver(RegisterClientSchema) 
   });
   
   const gender = watch('gender');
   const maritalStatus = watch('maritalStatus');
   const company = params.get('fi') || '';
   const member = params.get('fe') || '';

   const submit: SubmitHandler<any> = async (data) => {
        const { name, email, password } = data;
        
        try {
            // if (!terms) return;
            data.accountType = UserTypes.CLIENT;

            const result = await registerAuthUser(email, password);
            
            await confirmUser(email);
            await markEmailAsVerified(email);

            const client = {
                userId: result?.UserSub || `${Math.random().toFixed(4)}-${Math.random().toFixed(4)}`.replaceAll('.', 'e'),
                userType: UserTypes.CLIENT,
                userInfo: {
                    name: data.name,
                    gender: data.gender,
                    email: data.email,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    zip: data.zip,
                    phone: data.phone,
                    companyId: company,
                    memberId: member,
                },
                metadata: {
                    accessLevel: ACCESS_LEVEL.CLIENT,
                    firstLogin: true,
                    createdAt: new Date(),
                    updateAt: new Date(),
                    agreedToTerms: terms,
                    cus_id: '',
                    stripeId: '',
                },
                neatt: {
                    behindInChildSupport: data.behindInChildSupport?.toLowerCase() === 'true',
                    buyingMaritalResidence: data.buyingMaritalResidence?.toLowerCase() === 'true',
                    causeOfBankruptcy: data?.causeOfBankruptcy,
                    consideringMarriage: data.consideringMarriage?.toLowerCase() === 'true',
                    declaringBankruptcy: data.declaringBankruptcy?.toLowerCase() === 'true',
                    decreasedWorkHours: data.decreasedWorkHours?.toLowerCase() === 'true',
                    defaulted: data.defaulted?.toLowerCase() === 'true',
                    deniedMortgage: data.deniedMortgage?.toLowerCase() === 'true',
                    deniedRental: data.deniedRental?.toLowerCase() === 'true',
                    dividingMaritalAssets: data.dividingMaritalAssets?.toLowerCase() === 'true',
                    homeRepairs: data.homeRepairs?.toLowerCase() === 'true',
                    householdMemberPassed: data.householdMemberPassed?.toLowerCase() === 'true',
                    injuryOrIllness: data.injuryOrIllness?.toLowerCase() === 'true',
                    madeRedundant: data.madeRedundant?.toLowerCase() === 'true',
                    maritalStatus: data.maritalStatus?.toLowerCase() === 'true',
                    newResident: data.newResident?.toLowerCase() === 'true',
                    noMedicalInsurance: data.noMedicalInsurance?.toLowerCase() === 'true',
                    reducedIncome: data.reducedIncome?.toLowerCase() === 'true',
                    separatedOrConsideringDivorce: data.separatedOrConsideringDivorce?.toLowerCase() === 'true',
                    spousePassed: data.spousePassed?.toLowerCase() === 'true',
                    taxDebt: data.taxDebt?.toLowerCase() === 'true',
                    unplannedExpenditures: data.unplannedExpenditures?.toLowerCase() === 'true',
                    unstableIncome: data.unstableIncome?.toLowerCase() === 'true',
                }
            }

            const ipResult = await axios.get('https://api.ipify.org?format=json');

 
            const cbcResult = await addCreditUser({
                uid: client.userId,
                name: client.userInfo.name,
                email: client.userInfo.email,
                password: data.password,
                ip: ipResult.data.ip
            });

            const result2 = convert.xml2js(cbcResult, { compact: true });
            const customer = await stripeHelpers.addNewCustomer(client);
            
            //@ts-ignore
            client.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];
            client.metadata.stripeId = customer?.data.id;
            //@ts-ignore
            await clientHelpers.addClient(client);

            let assistableIssues: number = 0;
            const taxIssues = Boolean(data.taxDebt || data.declaringBankruptcy);

            for (let key in client.neatt) {
                console.log({
                    key,
                    //@ts-ignore
                    neatt: client.neatt[key]
                })
                //@ts-ignore
                if(client.neatt[key]) {
                    console.log('enter')
                    assistableIssues += 1;
                }
            }

            console.log({
                assistableIssues,
                taxIssues
            })

            if(assistableIssues > 0) {
                navigate(`/client-payment?ui=${client.userId}&si=${customer?.data.id}&name=${client.userInfo.name}`);
            } else if(assistableIssues === 0 && taxIssues) {
                navigate('/unable-to-assist');
            } else {
                navigate('/login');
            }
        } catch (error: any) {
            setError(error?.message || 'Failed To Register Account')
        }
    };

    return (
        <div >
                <div className='font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                    <h4>
                        COMPLETE ACCOUNT REGISTRATION
                    </h4>
                </div>
                {
                    error && <p className='text-danger'>{error}</p>
                }
    
                <ClientOnboarding 
                    register={register} 
                    gender={gender} 
                    maritalStatus={maritalStatus}
                    submit={handleSubmit(submit)} 
                />
                
                <div className='flex flex-col'>                    
                    <Link to='/login' className='text-primary self-center mb-4'>
                        Already Have Account?
                    </Link>
                </div>
                
        </div>
    )
}

export default RegisterClientAccount;



