import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import PageTitle from "../../PageTitle";
import RadioGroup from "../../inputs/RadioGroup";
import Dropdown from "../../inputs/Dropdown";

interface IMaritalFormProps {
    register: UseFormRegister<any>;
    maritalStatus: string;
    errors?: any;
}

const MaritalForm: FunctionComponent<IMaritalFormProps> = ({ register, maritalStatus, errors }) => {
    const options = [
        {label: 'Yes', id: 'true'},
        {label: 'No', id: 'false'},
    ];

    return (
        <div className="py-10">
            <PageTitle title="Marital Information" />

            <div className="mb-10 max-w-[350px]">
            <Dropdown 
                    id="maritalStatus"
                    register={register}
                    onChange={() => {}}
                    currentValue={maritalStatus}
                    options={[
                        {
                            value: 'SINGLE',
                            label: 'Single'
                        },
                        {
                            value: 'MARRIED',
                            label: 'Married'
                        },
                        {
                            value: 'SEPARATED',
                            label: 'Separated'
                        },
                        {
                            value: 'DIVORCED',
                            label: 'Divorced'
                        },
                        {
                            value: 'WIDOW',
                            label: 'Widow/Widower'
                        },
                        {
                            value: 'PARTNERED',
                            label: 'Partnered'
                        },
                    ]}
                />
            </div>

            <div className="grid grid-cols-3 gap-4">
                <RadioGroup
                    question="Are you separated or considering divorce?"
                    name="separatedOrConsideringDivorce"
                    register={register}
                    options={options}

                />

                <RadioGroup
                    question="Are you in the process of dividing marital assets?"
                    name="dividingMaritalAssets"
                    register={register}
                    options={options}
                />

                <RadioGroup
                    question="Are you considering marriage?"
                    name="consideringMarriage"
                    register={register}
                    options={options}
                />

                <RadioGroup
                    question="Are you behind in paying or receiving child support?"
                    name="behindInChildSupport"
                    register={register}
                    options={options}

                />

                <RadioGroup
                    question="Are you considering buying a marital residence?"
                    name="buyingMaritalResidence"
                    register={register}
                    options={options}
                />

                <RadioGroup
                    question="Has a spouse passed away?"
                    name="spousePassed"
                    register={register}
                    options={options}
                />
            </div>
        </div>
    )
}

export default MaritalForm;