import { FunctionComponent } from "react";
import Button from "./Button";
import { sendgridHelpers } from "../utils/ClientVitalsAPI";
import { useDashboard } from "../hooks/useDashboard";
import { NotificationThemes } from "../context/DashboardContext";


interface IScheduleFollowUpProps {
    id: string;
    name: string;
    email: string;
}

const ScheduleFollowUp: FunctionComponent<IScheduleFollowUpProps> = ({ id, name, email }) =>{
    const { updateNotification } = useDashboard();

    const sendScheduleEmail = async () => {
        try {
            await sendgridHelpers.sendFollowup({name, email});

            updateNotification({
                message: "Email Sent",
                theme: NotificationThemes.SUCCESS
            })
        } catch(error) {
            console.log(error);

            updateNotification({
                message: "Email Failed To Send",
                theme: NotificationThemes.FAIL
            })
        }
    }

    return (
        <Button 
            text="Schedule Follow-Up" 
            disabled={(!id.length || !name.length || !email.length)} 
            onClick={sendScheduleEmail} 
            styles="w-[200px] ml-4"
        />
    )
}

export default ScheduleFollowUp;