import { FunctionComponent } from "react";
import SlimTile from "../tiles/SlimTile";
import AddPaymentModal from "../modals/AddPaymentModal";
import { Link } from "react-router-dom";
import { CARD_BRANDS } from "../../constants/enums";
import {ReactComponent as VisaLogo} from '../../assets/logos_visa.svg';
import {ReactComponent as MasterCardLogo} from '../../assets/logos_mastercard.svg';
import { IPaymentData } from "../../context/StripeContext";

interface IManagePaymentMethodProps {
    paymentMethod: IPaymentData | null
}

const ManagePaymentMethod: FunctionComponent<IManagePaymentMethodProps> = ({ paymentMethod }) => {
    const generateLogo = () => {
        switch(paymentMethod?.card.brand.toUpperCase()) {
             case CARD_BRANDS.MASTERCARD.toUpperCase():
                  return <MasterCardLogo />

             case CARD_BRANDS.VISA.toUpperCase():
                  return <VisaLogo />

             case CARD_BRANDS.MASTERCARD.toUpperCase():
                  return <MasterCardLogo />
        }
   }

    return (
        <SlimTile>
            {!paymentMethod ? (
                <div className="flex flex-col justify-around items-center">
                    <p className="text-primary text-2xl text-center my-6">
                        Add Payment Method
                    </p>

                    <AddPaymentModal />
                </div>
            ) : (
                <div>
                    <h5 className="uppercase font-medium text-md text-[#AEA8A8] my-3">
                        Active Card
                    </h5>

                    <div className="border border-secondary rounded-sm h-[130px] flex align-start justify-between py-3 px-5">
                        <div className="flex justify-between">
                                <div className="py-2 mr-8">{generateLogo()}</div>

                                <div>
                                    <p className="text-md">{paymentMethod.card.brand.toUpperCase()}</p>
                                    <p className="text-sm">**** **** **** {paymentMethod.card.last4}</p>
                                    <p className="text-sm">Exp: {paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}</p>
                                </div>
                        </div>
                        
                        <Link className='h-fit justify-self-end' to='/billing/payment'>
                                <p className="text-primary hover:underline">  
                                    manage
                                </p>
                        </Link>
                    </div>
                </div>
            )}
    </SlimTile>
    )
}

export default ManagePaymentMethod;