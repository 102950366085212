import { FunctionComponent, useState, useEffect } from "react";
import { referralHelpers } from "../../utils/ClientVitalsAPI";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table";
import { useDashboard } from "../../hooks/useDashboard";
import { NotificationThemes } from "../../context/DashboardContext";
import Loading from "../../components/Loading";
import { partnersTableActions } from "../../components/actionMenu/types";
import InvitePartnerModal from "../../components/modals/InvitePartnerModal";

const Partners: FunctionComponent = () => {
    const [accounts, setAccounts] = useState<any>(null);
    const { updateNotification } = useDashboard();

    const fetchReferralAccounts = async () => {
        try {
            const result = await referralHelpers.getReferralAccounts();

            setAccounts(result);
        } catch (error) {
            setAccounts([]);
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Fetch Partners'
            });
        }
    }
        
    const columnHeaders: string[] = ['checkbox', 'name', 'email', 'phone','city', 'state', 'actions']
    const formattedAccounts: Record<string, any>[] = accounts?.map((user: any) => {
        return {
            name: user.userInfo.name,
            email: user.userInfo.email,
            phone: user.userInfo.phone,
            status: user.metadata.status,
            city: user.userInfo.city,
            state: user.userInfo.state,
            userId: user.userId,
            userType: user.userType
        } 
    });

    useEffect(() => {
        fetchReferralAccounts();
    }, []);
    
    return (
        <DashboardPageContainer>
            <PageTitle title='Partners' />
            <div className="flex justify-end items-center space-x-4 mb-4">
                <InvitePartnerModal />
            </div>
            {
                accounts === null ? <Loading />
                                  : <Table
                                        columnHeaders={columnHeaders}
                                        records={formattedAccounts}
                                        tableActions={partnersTableActions}
                                        customButton={null}
                                        linkBase="/dashboard/partners"
                                    />
            }
        </DashboardPageContainer>
    )
}

export default Partners;