import axios from "axios";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const getMessengerToken = async (userId: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/messenger/token/${userId}`);

        return result?.data?.token;
    } catch(error) {
        throw error;
    }
}

export default {
    getMessengerToken
}