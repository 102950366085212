import { ReactComponent as WhiteBellIcon } from '../../assets/notifications-icon-white.svg';
import { ReactComponent as RedBellIcon } from '../../assets/notification-icon-red.svg';
import { FunctionComponent, useState } from 'react';
import { useDashboard } from '../../hooks/useDashboard';
import SubMenuItem from './SubMenuItem';

interface ISubMenuProps {
    showText: boolean;
};

const SubMenu: FunctionComponent<ISubMenuProps> = ({ showText }) => {
    const [showNoti, setShowNoti] = useState(false);
    const {notifications} = useDashboard();
    const handleClick = () => {
        setShowNoti(!showNoti)
    };

    const recentNotifications = notifications?.slice(0,5);
    const unread = recentNotifications.filter(({data: {read}}) => !read)?.length;

    return (
        <div className="w-[150px] flex justify-start items-start cursor-pointer relative">
            <div onClick={handleClick} className="w-[150px] flex justify-start items-start relative">
                {
                    unread > 0 ? <p className='absolute bottom-4 left-2 text-danger rounded-full font-semibold h-4 w-4 text-center text-xs'>{unread}</p> : <></>
                }
                {
                    unread > 0 ? <RedBellIcon className={`${showText ? "h-[18px] w-[18px]" : "h-[22px] w-[22px]"} mr-2`} /> 
                               : <WhiteBellIcon className={`${showText ? "h-[16px] w-[16px]" : "h-[22px] w-[22px]"} mr-2`} />
                }
                {showText && (<span className={`${unread > 0 ? 'font-semibold' : ''}`}>NOTIFICATIONS</span>)}
            </div>
            {showNoti && (
                <div className={`min-w-[250px] max-w-[325px] bg-white flex flex-col items-start border border-primary border-2 absolute z-50 rounded-md text-black ${showText ? 'left-44 bottom-0' : 'left-10 bottom-0'}`}>
                    {recentNotifications.map((notification, id) => (
                        <SubMenuItem 
                            text={notification.data.text} 
                            author={notification.data.author.name}
                            date={notification.data.date?.split(',')[0]}
                            read={notification.data.read}
                            key={id} 
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default SubMenu;