import { useState, useEffect } from 'react';
import { memberHelpers } from '../utils/ClientVitalsAPI';
import { useUser } from './useUser';
import { Member } from '../constants/members';
import { UserTypes } from '../constants';

const useMembers = () => {
    const { user: { userId, userType } } = useUser();
    const [members, setMembers] = useState<Member[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>(null);

    const fetchMembers = async () => {
        if(!loading) setLoading(true);
        try {
            const isOwner = userType.toLowerCase() === UserTypes.OWNER.toLowerCase();
            const isCVA = userType.toLowerCase() === UserTypes.MANAGER.toLowerCase()
            const fetchedMembers = (isOwner || isCVA) ? await memberHelpers.getAllMembers(userId) : await memberHelpers.getCompanyMembers(userId);

            setMembers(fetchedMembers);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getMemberById = (id: string) => {
        return members.find(({ userId }) => userId === id);
    }

    useEffect(() => {
        if(userId) fetchMembers();
    }, []);

    return { 
        members, 
        loading, 
        error, 
        fetchMembers, 
        getMemberById,
    }
}

export default useMembers;