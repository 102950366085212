import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { ApplicationTypes } from "../../constants";
import { APPLICATION_STATUS } from "../../constants/enums";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

// Credit Helpers
export const formatPullRequestApplication = (data: Record<any, any>) => {
    return {
        applicationId: uuidv4(),
        applicationType: ApplicationTypes.PULL,
        applicationFormData: {
            name: data.name.replace('-', ' '),
            address: data.address || undefined,
            city: data.city || undefined,
            state: data.state || undefined,
            zip: data.zip || undefined,
            phone: data.phone || undefined,
            email: data.email.toLowerCase(),
            dob: data.dob || undefined,
            consent: data.consent || undefined,
            ssn: data.ssn || undefined
        },
        metadata: {
            memberId: data.memberId,
            companyId: data.companyId,
            createdAt: Date.now().toLocaleString(),
            browserUsed: '',
            status: APPLICATION_STATUS.REQUESTED,
            credit_guid: null,
            app_id: null
        },
        coapplicant1: {
            exists: Boolean(data.name1),
            name: data.name1 || undefined,
            address: data.address1 || undefined,
            city: data.city1 || undefined,
            state: data.state1 || undefined,
            zip: data.zip1 || undefined,
            dob: data.dob1 || undefined,
            ssn: data.ssn1 || undefined,
            consent: data.consent1 || undefined
        },
        coapplicant2: {
            exists: Boolean(data.name2),
            name: data.name2 || undefined,
            address: data.address2 || undefined,
            city: data.city2 || undefined,
            state: data.state2 || undefined,
            zip: data.zip2 || undefined,
            dob: data.dob2 || undefined,
            ssn: data.ssn2 || undefined,
            consent: data.consent2 || undefined
        },
        coapplicant3: {
            exists: Boolean(data.name3),
            name: data.name3 || undefined,
            address: data.address3 || undefined,
            city: data.city3 || undefined,
            state: data.state3 || undefined,
            zip: data.zip3 || undefined,
            dob: data.dob3 || undefined,
            ssn: data.ssn3 || undefined,
            consent: data.consent3 || undefined
        },
        coapplicant4: {
            exists: Boolean(data.name4),
            name: data.name4 || undefined,
            address: data.address4 || undefined,
            city: data.city4 || undefined,
            state: data.state4 || undefined,
            zip: data.zip4 || undefined,
            dob: data.dob4 || undefined,
            ssn: data.ssn4 || undefined,
            consent: data.consent4 || undefined
        },
    }
}

const addPullApplication = async (app: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/credit/apps/add`, app);

        return result
    } catch (error) {
        throw error;
    }
}

const fetchAppsByClient = async (email: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/credit/allapps/${email}`);

        const apps = result?.data?.result?.Items;
        //@ts-ignore
        apps.sort((a: any, b: any) => new Date(b.metadata.applicationSubmit) - new Date(a.metadata.applicationSubmit));
        
        return apps
    } catch (error) {
        throw error;
    }
}

const fetchReport = async (link: string) => {
    try {
        const result = await axios.post(`${BASE_URL}/credit/report`, {link});
    
        return result?.data?.result;
    } catch (error) {
        throw error;
    }
}

export default {
    formatPullRequestApplication,
    addPullApplication,
    fetchAppsByClient,
    fetchReport
}