import { useUser } from "../../hooks/useUser";
import { useLocation } from "react-router-dom";
import { useDashboard } from "../../hooks/useDashboard";
import { ConfirmPullModal } from "../../components/modals";
import { NotificationThemes } from "../../context/DashboardContext";
import { analyzeReport, parseCreditReport } from "../../utils/TriMergeDifferential";
import { creditHelpers, stripeHelpers } from "../../utils/ClientVitalsAPI";
import { useState, FunctionComponent, useRef, useEffect } from "react";
import { ACCESS_LEVEL, PRODUCT_LIST_PROD, PRODUCT_LIST_STAGE } from "../../constants/enums";
import { CreditUnions, firstPullSingleClientCredit, recallClientReport } from "../../utils/SoftPullAPI";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import useCreditApplications from "../../hooks/useCreditApplications";
import ReferClientModal from "../../components/modals/ReferClientModal";
import useCustomer from "../../hooks/useCustomer";
import useClients from "../../hooks/useClients";
import useMembers from "../../hooks/useMembers";
import PageTitle from "../../components/PageTitle";
import Loading from "../../components/Loading";
import convert from 'xml-js';
import { extractPastDueTotal, runRiskAnalysis } from "../../utils/CreditExtractions";
import CreditApplicationInfo from "../../components/creditReport/creditApplicationInfo";
import CreditReport from "../../components/creditReport/CreditReport";
import WideTile from "../../components/tiles/WideTile";
import FeedbackModal from "../../components/modals/FeedbackModal"

const ClientApplicationId: FunctionComponent = () => {
    const iframeRefReportA = useRef(null);
    const iframeRefReportB = useRef(null);
    const iframeRefReportC = useRef(null);

    const refs = [iframeRefReportA, iframeRefReportB, iframeRefReportC]

    const { user } = useUser();
    const { customer, paymentMethod } = useCustomer()
    const { updateNotification } = useDashboard();
    const { getClientById } = useClients();
    const { getMemberById } = useMembers();
    
    const { pathname } = useLocation();
    const pathArray = pathname.split('/');
    const clientId = pathArray[pathArray.length - 3]
    const { getApplication } = useCreditApplications(clientId);
    
    const appId = pathArray[pathArray.length - 1];
    const application = getApplication(appId);
    const member = getMemberById(application?.metadata?.member);

    const [creditReportType, setCreditReportType] = useState<CreditUnions>(CreditUnions.TRI_MERGE);
    const [creditReport, setCreditReport] = useState<any>(null);
    const [scoreCardReports, setScoreCardReports] = useState<Record<any, any>>([]);
    const [loading, setLoading] = useState(false);
    const [reportASummary, setReportASummary] = useState<Record<any, any> | null>(null);
    const [reportBSummary, setReportBSummary] = useState<Record<any, any> | null>(null);
    const [reportCSummary, setReportCSummary] = useState<Record<any, any> | null>(null);
    const [riskReport, setRiskReport] = useState<Record<any, any> | null>(null);
    const [pastDue, setPastDue] = useState<number | null>(null);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
    
    const productList = process.env.REACT_APP_ENV === 'prod' ? PRODUCT_LIST_PROD : PRODUCT_LIST_STAGE;

    useEffect(() => {
        if (scoreCardReports.length && iframeRefReportA.current) {
            setTimeout(() => analyzeReport(iframeRefReportA, setReportASummary), 2000);
        }
    }, [scoreCardReports, iframeRefReportA]);

    useEffect(() => {
        if (scoreCardReports.length && iframeRefReportB.current) {

            setTimeout(() => analyzeReport(iframeRefReportB, setReportBSummary), 2000);
        }
    }, [scoreCardReports, iframeRefReportB])

    useEffect(() => {
        if (scoreCardReports.length && iframeRefReportC.current) {

            setTimeout(() => analyzeReport(iframeRefReportC, setReportCSummary), 2000);
        }
    }, [scoreCardReports, iframeRefReportC])

    useEffect(() => {
        if(reportASummary && reportBSummary && reportCSummary) {
            const pastDueResult = extractPastDueTotal(reportASummary, reportBSummary, reportCSummary);
            const riskReportResult = runRiskAnalysis(reportASummary, reportBSummary, reportCSummary);
            
            setRiskReport(riskReportResult);
            setPastDue(pastDueResult);
        }
    }, [reportASummary, reportBSummary, reportCSummary]);

    if (!application) return <Loading />
    
    const pullCredit = async (password: string) => {
        setLoading(true);
        try {
            const result = (!application.metadata.app_id && !application.metadata.credit_guid)
                ? await firstPullSingleClientCredit(
                application,
                //@ts-ignore
                user,
                password,
                application.applicationFormData.ssn,
                creditReportType
                ) : await recallClientReport(
                    application,
                    //@ts-ignore
                    user,
                    password,
                    application.applicationFormData.ssn,
                    creditReportType
                );
            
            const jsResult = convert.xml2js(result, { compact: true });
        
            if (!application.metadata.app_id || !application.metadata.credit_guid) {
                //@ts-ignore
                const app_id = jsResult["XML_INTERFACE"]["APP_ID"]["_text"].replace('{', '').replace('}', '');
          
                //@ts-ignore
                const BUREAU_TYPE = jsResult["XML_INTERFACE"]["CREDITREPORT"]["BUREAU_TYPE"];
                let credit_guid;
            
                if (Array.isArray(BUREAU_TYPE)) {
                    credit_guid = BUREAU_TYPE
                } else {
                    //@ts-ignore
                    credit_guid = BUREAU_TYPE["CREDIT_GUID"]["_text"];
                }
                application.metadata.app_id = app_id;
                application.metadata.credit_guid = credit_guid;
             
                await creditHelpers.addPullApplication(application);
            }
            
            await parseCreditReport(jsResult, setScoreCardReports);
            setCreditReport(jsResult);

            setTimeout(() => setFeedbackModalOpen(true), 4000);

        } catch (error) {
            updateNotification({
                theme: NotificationThemes.FAIL,
                message: 'Failed To Pull Credit'
            })
        } finally {
            setLoading(false)
        }
    }

    const payAndPull = async (password: string) => {
        if(!customer || !paymentMethod) return;
        const productId = creditReportType === CreditUnions.TRI_MERGE ? productList.TRI_MERGE : productList.SINGLE_PULL
        
        try {
            const paymentResult = await stripeHelpers.chargeCustomer(
                customer?.id,
                paymentMethod?.id,
                productId
            );

            if(paymentResult.success) {
                await pullCredit(password);
            }
        } catch(error) {
            throw error;
        }
    }

    return (
        <DashboardPageContainer>
            <PageTitle
                title={`${application?.applicationFormData?.name}'s Pull Application`}
                ActionButton={
                    user.metadata.accessLevel !== ACCESS_LEVEL.REFERRAL ? () => (
                        <div className="flex justify-between">
                            <ConfirmPullModal
                                pullCredit={(user?.metadata?.accessLevel === ACCESS_LEVEL.OWNER || user?.metadata?.accessLevel === ACCESS_LEVEL.CVA) ? pullCredit : pullCredit}
                                creditReportType={creditReportType}
                                setCreditReportType={setCreditReportType}
                            />
                            <ReferClientModal existingClient={getClientById(clientId)} />
                        </div>
                    ) : () => <></>
                }
            />
            <FeedbackModal
            isModalOpen={feedbackModalOpen}
            setIsModalOpen={() => setFeedbackModalOpen(false)}
            />
            {
                loading ? <Loading />
                    : (creditReport && !loading) ? (
                        <>
                            <CreditReport
                                application={application}
                                reportASummary={reportASummary}
                                reportBSummary={reportBSummary}
                                reportCSummary={reportCSummary}
                                pastDue={(typeof pastDue === 'number') ? pastDue : 'unknown'}
                                riskReport={riskReport || {}}
                                scoreCardReports={scoreCardReports}
                                creditReportType={creditReportType}
                                user={member || user}
                                client={getClientById(clientId) || {}}
                            />

                            <div>
                                {
                                    scoreCardReports.map((scoreCard: Record<any, any>, index: number) => (
                                        <div className="mx-4">
                                            <iframe
                                                key={scoreCard.score}
                                                ref={refs[index]}
                                                srcDoc={scoreCard.report}
                                                style={{ width: '5px', height: '5px', border: 'none', opacity: 0 }}
                                            />
                                        </div>
                                    ))
                                }
                            </div>

                            <WideTile>
                                <iframe
                                    srcDoc={creditReport["XML_INTERFACE"]["CREDITREPORT"]["REPORT"]["_cdata"]}
                                    style={{ width: '100%', height: '500px', border: 'none' }}
                                />
                            </WideTile>
                        </>
                ) : <CreditApplicationInfo application={application} />
            }

        </DashboardPageContainer>
    )
}
export default ClientApplicationId;