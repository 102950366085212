import { FunctionComponent } from 'react';
import { ReactComponent as ViewIcon } from '../../assets/view-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/archive-icon.svg';
import { ReactComponent as ApproveIcon } from '../../assets/approve-check.svg';
import { confirmUser, enableUser } from '../../utils/Cognito';
import { ACCESS_LEVEL } from '../../constants';
import { stripeHelpers, sendgridHelpers, memberHelpers, companyHelpers, clientHelpers, subscriptionHelpers } from '../../utils/ClientVitalsAPI';
import { addCreditUser } from '../../utils/SoftPullAPI';
import axios from 'axios';
import convert from 'xml-js';

type MenuAction = {
    label: string;
    action: (...args : any[]) => void;
    navigate?: boolean;
    linkTo?: string;
    Icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const clientTableActions: MenuAction[] = [
    {
        label: 'View',
        action: () => console.log('view'),
        navigate: true,
        linkTo: '/dashboard/clients',
        Icon: ViewIcon
    },
    {
        label: 'Edit',
        action: () => console.log('edit'),
        navigate: true,
        linkTo: '/dashboard/clients',
        Icon: EditIcon
    },
    {
        label: 'Archive',
        action: async (record: Record<any, any>) => {
            console.log(record);

            try {
                const result = await clientHelpers.archiveClient(record.userId)

                return result;
            } catch(error) {
                throw error;
            }
        },
        Icon: ArchiveIcon
    },
];

const partnersTableActions: MenuAction[] = [
    {
        label: 'View',
        action: () => console.log('view'),
        navigate: true,
        linkTo: '/dashboard/partners',
        Icon: ViewIcon
    },
    {
        label: 'Edit',
        action: () => console.log('edit'),
        navigate: true,
        linkTo: '/dashboard/partners',
        Icon: EditIcon
    },
    {
        label: 'Archive',
        action: async (record: Record<any, any>) => {
            console.log(record);

            try {
                const result = await clientHelpers.archiveClient(record.userId)

                return result;
            } catch(error) {
                throw error;
            }
        },
        Icon: ArchiveIcon
    },
];

const memberTableActions: MenuAction[] = [
    {
        label: 'View',
        action: () => console.log('view'),
        navigate: true,
        linkTo: '/dashboard/members',
        Icon: ViewIcon
    },
    {
        label: 'Edit',
        action: () => console.log('edit'),
        navigate: true,
        linkTo: '/dashboard/members',
        Icon: EditIcon
    },
    {
        label: 'Archive',
        action: async (record: Record<any, any>) => {
            console.log(record);

            try {
                const result = await memberHelpers.archiveMember(record.userId)

                return result;
            } catch(error) {
                throw error;
            }
        },
        Icon: ArchiveIcon
    },
];

const companyTableActions: MenuAction[] = [
    {
        label: 'View',
        action: () => console.log('view'),
        navigate: true,
        linkTo: '/dashboard/companies',
        Icon: ViewIcon
    },
    {
        label: 'Edit',
        action: () => console.log('edit'),
        navigate: true,
        linkTo: '/dashboard/companies',
        Icon: EditIcon
    },
    {
        label: 'Archive',
        action: async (record: Record<any, any>) => {
            console.log(record);

            try {
                const result = await companyHelpers.archiveCompany(record.userId)

                return result;
            } catch(error) {
                throw error;
            }
        },
        Icon: ArchiveIcon
    },
];

const applicationTableActions: MenuAction[] = [
    {
        label: 'Approve',
        action: async (record, approverId: string, cb: () => void) => {
            try {
                await enableUser(record.email);
                await confirmUser(record.email);
                console.log(record)
                const newUser = {
                    userId: record.id,
                    userType: record.account,
                    userInfo: {
                        name: record.name,
                        address: record.address,
                        city: record.city,
                        state: record.state,
                        country: record.country,
                        zip: record.zip,
                        phone: record.phone,
                        email: record.email,
                        ein: record.ein
                    },
                    metadata: {
                        approvedBy: approverId,
                        accessLevel: ACCESS_LEVEL.REFERRAL
                    }
                }

                const ipResult = await axios.get('https://api.ipify.org?format=json');

                const cbcResult = await addCreditUser({
                    uid: newUser.userId,
                    name: newUser.userInfo.name,
                    email: newUser.userInfo.email,
                    password: 'TempPassword',
                    ip: ipResult.data.ip
                });

                const result2 = convert.xml2js(cbcResult, { compact: true });
                
                //@ts-ignore
                newUser.metadata.cus_id = result2["XML_INTERFACE"]["CUS_ID"]["_text"];
                
                await subscriptionHelpers.approveSubscriberApplication(newUser)
                await sendgridHelpers.sendWelcome({ name: record.name, email: record.email });
                await stripeHelpers.addNewCustomer(newUser);
                if(cb) cb();
            } catch (error) {
                throw error;
            }
        },
        Icon: ApproveIcon
    }
];

export type {
    MenuAction
}

export {
    clientTableActions,
    memberTableActions,
    companyTableActions,
    applicationTableActions,
    partnersTableActions
}