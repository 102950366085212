import { FunctionComponent } from "react";
import { useDashboard } from "../hooks/useDashboard";
import Support from "./support";
import Toast from "./notifications/Toast";
import Messenger from "./messenger";

const DashboardPageContainer: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const { notification } = useDashboard();

    return (
        <section className="h-full min-h-dvh w-[100%] px-6 pl-10 py-2 relative" data-testid="dashboard-page-container">
            {
            notification.message && (<Toast message={notification.message} theme={notification.theme} />)
            }
            <Support />
            {children}
        </section>
    );
};

export default DashboardPageContainer;



