type Author = {
    id: string;
    name: string;
}

export type NotificationData = { 
    author: Author;
    text: string;
    date: string;
    read: boolean;
}

export type Notification = {
    notificationId: string;
    sentToId: string;
    data: NotificationData;
}

export enum AUTO_NOTIFICATIONS {
    NEW_CLIENT = 'client registered',
    CREDIT_FORM = 'credit form submission'
}