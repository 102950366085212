import { useEffect, FunctionComponent } from "react";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { ACCESS_LEVEL } from "../../constants";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import Loading from "../../components/Loading";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table";
import useSupport from "../../hooks/useSupport";

const SupportTickets: FunctionComponent = () => {
    const { user, loading } = useUser();
    const { tickets } = useSupport();
    
    const navigate = useNavigate();

    const headerList = ['checkbox', 'subject', 'action', 'page', 'details', 'screenshot', 'created']
 
    const formattedTickets = tickets.map((ticket: Record<any, any>) => {

        return {
            subject: ticket?.data?.subject,
            action: ticket?.data?.action,
            page: ticket?.data?.page,
            details: ticket?.data?.details,
            screenshot: <a href={ticket?.data?.screenshot}>Download</a>,
            created: ticket?.metadata?.createdAt
        }
    });


    useEffect(() => {
        if(
            user?.metadata?.accessLevel !== ACCESS_LEVEL.OWNER
            && user?.metadata?.accessLevel !== ACCESS_LEVEL.CVA
        ) navigate('/dashboard/clients');
    }, []);

    return (
        <DashboardPageContainer>
            <PageTitle title="Support Tickets" />
            
            {
                loading ? <Loading />
                        : <Table
                            columnHeaders={headerList}
                            records={formattedTickets}
                            tableActions={[]}
                            linkBase="/dashboard/tickets"
                        />
            }
        </DashboardPageContainer>
    )
}

export default SupportTickets;