import React, { useState, useEffect } from "react";
import FeedbackForm from "./FeedbackForm";
import Modal from "../Modal";
import { NotificationThemes } from "../../../context/DashboardContext";
import { useDashboard } from "../../../hooks/useDashboard";
import { useUser } from "../../../hooks/useUser";
import {supportHelpers} from "../../../utils/ClientVitalsAPI"

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

interface IFeedbackModalProps {
    isModalOpen: boolean;
    setIsModalOpen: Function
}

const FeedbackModal: React.FC<IFeedbackModalProps> = ({ isModalOpen, setIsModalOpen }) => {
    const [feedback, setFeedback] = useState<string>("");
    const { updateNotification } = useDashboard();
    const {user}=useUser()
    const handleSubmit = async () => {
        if (feedback.trim() === "") {
            updateNotification({
                message: "Feedback cannot be empty.",
                theme: NotificationThemes.FAIL,
            });
            return;
        }

    const newTicket =  { 
            submittedBy: user.userId,
            subject: "Credit Pull Feedback",
            page: "/dashboard/clients/clientId/applications/appId",
            action: "Credit Pull",
            details: feedback,
            screenshot: null,
          }

    const response = await supportHelpers.addSupportTicket (newTicket)
    updateNotification({
        message: "Feedback submitted successfully!",
        theme: NotificationThemes.SUCCESS,
    });
    setIsModalOpen(false); // Close the modal
    };

    const handleCancel = () => setIsModalOpen(false);

    return (
        <Modal
            open={isModalOpen}
            title="Your Feedback Matters"
            content={
                <FeedbackForm
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            }
            onClose={handleCancel}
        />
    );
};

export default FeedbackModal;


