import { FunctionComponent, useState } from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import useCustomer from "../hooks/useCustomer";
import AddPaymentModal from "../components/modals/AddPaymentModal";
import { TextInput } from "../components/inputs";
import { useStripe } from "@stripe/react-stripe-js";
import { PRODUCT_LIST_PROD, PRODUCT_LIST_STAGE } from "../constants/enums";
import { stripeHelpers } from "../utils/ClientVitalsAPI";
import { CARD_BRANDS } from "../constants/enums";
import {ReactComponent as VisaLogo} from '../assets/logos_visa.svg';
import {ReactComponent as MasterCardLogo} from '../assets/logos_mastercard.svg';


const BookFollowUp: FunctionComponent = () => {
    const {loading, customer, paymentMethod} = useCustomer();
    const navigate = useNavigate();
    const stripe = useStripe();
    const [paid, setPaid] = useState<boolean>(false);
    const [discountCode, setDiscountCode] = useState<string>('');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const generateLogo = () => {
        switch(paymentMethod?.card.brand.toUpperCase()) {
             case CARD_BRANDS.MASTERCARD.toUpperCase():
                  return <MasterCardLogo />

             case CARD_BRANDS.VISA.toUpperCase():
                  return <VisaLogo />

             case CARD_BRANDS.MASTERCARD.toUpperCase():
                  return <MasterCardLogo />
        }
   }

    const submitPayment = async () => {
        if(!paymentMethod?.id || !stripe || !customer?.id) return {result: false};
        setIsProcessing(true);
        try {      
            const result = await stripeHelpers.chargeCustomer(customer?.id, paymentMethod?.id, PRODUCT_LIST_PROD.FOLLOWUP_APPOINTMENT, discountCode)

            setPaid(true)
          } catch (error: any) {
            console.log(error);
            return {
                result: false
            }
          } finally {
            setIsProcessing(false);
          }  
     }


    if(!customer && !loading) return (
        <div className="flex flex-col items-center justify-center">
            <p>Please Login and Come Back to This Page</p>
            <Button 
                text="Login"
                onClick={() => navigate('/login')}
            />
        </div>
    )

    return (
        <div className="flex flex-col justify-center items-center mt-16">
            {
                !paymentMethod ? (
                    <div className="flex flex-col justify-around items-center">
                        <p className="text-primary text-2xl text-center my-6">
                            Add Payment Method
                        </p>

                        <AddPaymentModal />
                    </div>
                ) : (
                    <div className={`w-[750px] mr-10 bg-white rounded-lg border-primary border-2`}>
                        <div className='rounded-t-md font-semibold uppercase text-xl h-[75px] py-8 text-white bg-primary flex items-center justify-center'>
                            <h4>
                                Book an Appointment
                            </h4>
                        </div>

                        <div className="flex justify-between w-fit mx-auto mt-6">
                                <div className="py-2 mr-8">{generateLogo()}</div>

                                <div>
                                    <p className="text-md">{paymentMethod.card.brand.toUpperCase()}</p>
                                    <p className="text-sm">**** **** **** {paymentMethod.card.last4}</p>
                                    <p className="text-sm">Exp: {paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}</p>
                                </div>
                        </div>

                        <form className='w-full p-8 flex flex-col justify-center align-center'>
                            <div className="p-8">
                                <div className="flex align-end justify-between mb-4 text-lg">
                                    <p className="font-semibold">Follow-Up Appointment (NEATT):</p>
                                    
                                    <p className="font-normal">$140</p>
                                </div>

                                
                                <TextInput 
                                    id="couponCode" 
                                    label="Coupon Code" 
                                    placeholder="Coupon Code..." 
                                    onChange={(value: string) => setDiscountCode(value)}
                                />

                                <div className="flex align-end justify-between mb-4 text-lg">
                                    <p className="font-semibold">Total:</p>
                                    <p className="font-normal">{discountCode?.toUpperCase() === 'MLA43FPV' ? '$105' : '$140'}</p>
                                </div>
                            </div>
                            
                            {
                                paid ? (
                                    <>
                                        <a className="mx-auto p-4 rounded-md text-primary cursor w-fit px-4 border border-2 border-primary" href="https://calendly.com/jonathan-goffe-vermontconsumercredit/follow-up-appointment-case-management" target="_blank">
                                            View Calendar & Book Appointment
                                        </a>

                                        <p className="mt-10">Once appointment has been booked in a separate window, you can navigate away from this page</p>
                                    </>
                                ) : (
                                    <Button 
                                        text={isProcessing ? 'Processing...' : 'Submit Payment'} 
                                        type="submit" 
                                        disabled={!stripe || isProcessing} 
                                        styles="mx-auto mt-8 w-fit px-4"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            submitPayment()
                                        }}
                                    />
                                )
                            }

                        </form>
                    </div>  
                )
            }
        </div>
    )
}

export default BookFollowUp;