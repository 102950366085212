import axios from "axios";
import { PRODUCT_LIST_PROD, PRODUCT_LIST_STAGE } from "../../constants/enums";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

// Stripe Helpers
const addNewCustomer = async (userData: any) => {
    try {
        if(!userData || !userData?.userInfo?.name || !userData?.userInfo?.name) return;
 
        const result = await axios.post(`${BASE_URL}/payment/create-customer`, userData);

        return result
    } catch (error) {
        throw error;
    }
}

const addNewPaymentMethod = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/add-payment-method`, payload);

        return result
    } catch (error) {
        throw error;
    }
}

const setupStripeIntent = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/setup-intent`, payload);

        return result.data
    } catch (error) {
        throw error;
    }
}

const createStripeIntent = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/create-payment-intent`, payload);

        return result.data
    } catch (error) {
        throw error;
    }
}

const addSubscriber = async (payload: any) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/add-subscriber`, payload);

        return result.data
    } catch (error) {
        throw error;
    }
}

const fetchCustomerAndPayment = async (stripeId: string) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/retrieve-customer`, {
            customerId: stripeId
        });

        return result;
    } catch(error) {
        throw error;
    }
}

const fetchCustomerList = async () => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/retrieve-customer-list`);

        return result;
    } catch(error) {
        throw error;
    }
}

const chargeCustomer = async (customerId: string, paymentMethodId: string, product: PRODUCT_LIST_PROD | PRODUCT_LIST_STAGE, discountCode?: string) => {
  try {
    const result = await axios.post(`${BASE_URL}/payment/charge-customer`, {
        customerId,
        paymentMethodId,
        discountCode,
        productId: product
    });

    const data = result.data;
    if (data.success) {
        return data;
    } else {
        throw data;
    }
  } catch (error) {
      throw error
  }
};

const oneTimeCharge = async (customerId: string, paymentMethodId: string, amount: number) => {
    try {
      const result = await axios.post(`${BASE_URL}/payment/single-charge`, {
          customerId,
          paymentMethodId,
          amount
      });
  
      const data = result.data;
      if (data.success) {
          return data;
      } else {
          throw data;
      }
    } catch (error) {
        throw error
    }
};

const fetchStripeCatalogue = async () => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/product-catalogue`);

        return result;
    } catch(error) {
        throw error;
    }
}

const fetchStripeCustomerSubscriptions = async (customerId: string) => {
    try {
        const result = await axios.post(`${BASE_URL}/payment/customer-subscriptions`, {
            customerId
        });

        return result;
    } catch(error) {
        throw error;
    }
}

export default {
    addNewCustomer,
    addNewPaymentMethod,
    setupStripeIntent,
    createStripeIntent,
    addSubscriber,
    fetchCustomerAndPayment,
    fetchCustomerList,
    chargeCustomer,
    oneTimeCharge,
    fetchStripeCatalogue,
    fetchStripeCustomerSubscriptions
}