import { RiskScale } from "../constants/enums";

export const parseStringToObject = (input:string) => {
    const regex = /([^:]+): ([^:]+)(?=\s|$)/g; // Regular expression to match key-value pairs
    const obj: Record<any, any> = {};
    let match;
  
    while ((match = regex.exec(input)) !== null) {
      const key = match[1].trim().replace(/\s+/g, ''); // Remove spaces for the key
      const value = match[2].trim();
      obj[key] = value;
    }
  
    return obj;
}

export const isLessThan30DaysOld = (dateObj: Date): boolean => {
    const currentDate = new Date(); // Current date and time
    const thirtyDaysAgo = new Date();
  
    // Subtract 30 days from the current date
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
  
    // Check if the given date is greater than thirtyDaysAgo
    return dateObj > thirtyDaysAgo;
};

export const isValidDate = (dateString: string) => {
    // Check if the string matches the MM/DD/YYYY format
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  
    if (!dateRegex.test(dateString)) {
      return false; // Invalid format
    }
  
    // Parse the date string into a Date object
    const [month, day, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day); // Month is zero-based in JavaScript
  
    // Check if the resulting Date object matches the input
    if (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    ) return date;
}

export const addDateToArray = (dateString: string, dateArray: Date[]) => {
    const dateObj = isValidDate(dateString);
    if (dateObj) {
        // Check for duplicates by comparing timestamps
        const isDuplicate = dateArray.some(
            (existingDate) => existingDate.getTime() === dateObj.getTime()
        );

        if (!isDuplicate) {
            dateArray.push(dateObj);
        }
    }
};

export const extractInqueryDates = (text: HTMLElement, inquiriesDates: Date[]) => {
    const textArray = text.innerText.split('#');
    const inqueryTable = textArray[textArray?.length - 1];
    const tableArray = inqueryTable.split('\n');

    tableArray.forEach((inquery: string) => {
        const trimmedInquery = inquery.trim();

        if(trimmedInquery.length) {
            const cleanedString = trimmedInquery
            .toLowerCase()
            .replaceAll(/(\t\n|\n|\t)/gm, " ")
            .replace(/\s+/g, ' ');
            
            addDateToArray(cleanedString.substring(0,10), inquiriesDates)
        }
    });
}

export const extractCreditTypes = (element: HTMLElement) => {
    const cleanedString = element
        .innerText
        .toLowerCase()
        .replaceAll(/(\t\n|\n|\t)/gm, " ")
        .replace(/\s+/g, ' ');

    let revolving = false;
    let installment = false;

    if(cleanedString.includes('revolving')) revolving = true;
    if(cleanedString.includes('installment')) installment = true;

    return {
        revolving,
        installment
    }
}

export const extractWarnings = (element: HTMLElement) => {
    const cleanedString = element
    .innerText
    .toLowerCase()
    .replace('warning messages hide ', '')
    .replaceAll(/(\t\n|\n|\t)/gm, " ")
    .replace(/\s+/g, ' ');
    
    const warningMessages = cleanedString.split('-');

    warningMessages[0] = warningMessages[0].replace('warning messages hide ', '');
    return warningMessages;
}

export const extractJudgments = (element: HTMLElement) => {
    const cleanedString = element
        .innerText
        .toLowerCase()
        .replaceAll('total # of jdgs', 'jdgs')
        .replaceAll('mnths since last jdg', 'jdgMonths')
        .replaceAll('jdgs amt', 'jdgAmount')
        .replaceAll('small claims jdgs', 'smallClaims')
        .replaceAll('court jdgs', 'court')
        .replaceAll('foreclosure jdgs', 'foreclosure')
        .replaceAll(/(\t\n|\n|\t)/gm, " ")
        .replace(/\s+/g, ' ');

    const stringObj = parseStringToObject(cleanedString);
    const result = Number.isNaN(stringObj.jdgs) ? 0 : stringObj.jdgs
    return result
}

export const extractUtilization = (element: HTMLElement) => {
    const cleanedString = element
        .innerText
        .toLowerCase()
        .replaceAll('available %', 'availableCredit')
        .replaceAll(/(\t\n|\n|\t)/gm, " ")
        .replace(/\s+/g, ' ');
        
    const stringObj = parseStringToObject(cleanedString);

    return stringObj.availableCredit;
}

export const extractLateAndCollections = (element: HTMLElement) => {
    const cleanedString = element
        .innerText
        .toLowerCase()
        .replaceAll('30 days', '30days')
        .replaceAll('60 days', '60days')
        .replaceAll('90+ days', '90days')
        .replaceAll(/(\t\n|\n|\t)/gm, " ")
        .replace(/\s+/g, ' ');

    const stringObj = parseStringToObject(cleanedString);

    return {
        ninety: stringObj['90days'],
        sixty: stringObj['60days'],
        thirty: stringObj['30days'],
        collection: stringObj.collections.split(' ')[0]
    }
}

export const extractAmountPastDue = (element: HTMLElement) => {
    const cleanedString = element.innerText.toLowerCase().replaceAll(/(\t\n|\n|\t)/gm, " ").replace(/\s+/g, ' ');
    const stringObj = parseStringToObject(cleanedString)

    return stringObj.due;
}

export const extractInqueryCount30Days = (inqueries: Date[]) => {
    let totalRecentInquiries = 0;

    inqueries.forEach((inquery: Date) => {
        const isRecent = isLessThan30DaysOld(inquery);

        if(isRecent) totalRecentInquiries += 1;
    });

    return totalRecentInquiries
}

export const extractPastDueTotal = (reportASummary: Record<any, any> | null, reportBSummary: Record<any, any> | null, reportCSummary: Record<any, any> | null) => {
    const reportAPastDue = [Number(reportASummary?.thirtyDays || 0), Number(reportASummary?.sixtyDays || 0), Number(reportASummary?.ninetyDays || 0)]
    const reportBPastDue = [Number(reportBSummary?.thirtyDays || 0), Number(reportBSummary?.sixtyDays || 0), Number(reportBSummary?.ninetyDays || 0)]
    const reportCPastDue = [Number(reportCSummary?.thirtyDays || 0), Number(reportCSummary?.sixtyDays || 0), Number(reportCSummary?.ninetyDays || 0)]

    return reportAPastDue.reduce((accumulator, currentValue) => accumulator + currentValue, 0) +
            reportBPastDue.reduce((accumulator, currentValue) => accumulator + currentValue, 0) +
            reportCPastDue.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export const runRiskAnalysis = (reportASummary: Record<any, any> | null, reportBSummary: Record<any, any> | null, reportCSummary: Record<any, any> | null) => {
    if(!reportASummary && !reportBSummary && !reportCSummary) return {};
    const reportAPastDue = [Number(reportASummary?.thirtyDays || 0), Number(reportASummary?.sixtyDays || 0), Number(reportASummary?.ninetyDays || 0)]
    const reportBPastDue = [Number(reportBSummary?.thirtyDays || 0), Number(reportBSummary?.sixtyDays || 0), Number(reportBSummary?.ninetyDays || 0)]
    const reportCPastDue = [Number(reportCSummary?.thirtyDays || 0), Number(reportCSummary?.sixtyDays || 0), Number(reportCSummary?.ninetyDays || 0)]

    let inqueries: Date[] = [];

    if(reportASummary && reportBSummary && reportCSummary) {
        inqueries = [...reportASummary.inquiriesDates, ...reportBSummary.inquiriesDates, ...reportCSummary.inquiriesDates];
    }

    const experian = reportASummary?.scores[0]?.score;
    const transUnion = reportBSummary?.scores[0]?.score;
    const equifax = reportCSummary?.scores[0]?.score;
    
    const experianRisk = (experian < 680 && experian >= 640) ? RiskScale.MILD :
                            experian < 640 ? RiskScale.SEVERE : RiskScale.NONE;

    const transUnionRisk = (transUnion < 680 && transUnion >= 640) ? RiskScale.MILD :
                             transUnion < 640 ? RiskScale.SEVERE : RiskScale.NONE;

    const equifaxRisk = (equifax < 680 && equifax >= 640) ? RiskScale.MILD :
                          equifax < 640 ? RiskScale.SEVERE : RiskScale.NONE;

                          
    const experianPastDueRisk = (reportAPastDue[1] > 0 || reportAPastDue[2] > 0) ? RiskScale.SEVERE :
                                (reportAPastDue[0] > 1) ? RiskScale.MILD : RiskScale.NONE;
    
    const transUnionPastDueRisk = (reportBPastDue[1] > 0 || reportBPastDue[2] > 0) ? RiskScale.SEVERE :
                                (reportBPastDue[0] > 1) ? RiskScale.MILD : RiskScale.NONE;
    
    const equifaxPastDueRisk = (reportCPastDue[1] > 0 || reportCPastDue[2] > 0) ? RiskScale.SEVERE :
                                (reportCPastDue[0] > 1) ? RiskScale.MILD : RiskScale.NONE;

    const scoreRiskStatus = Math.max(experianRisk, transUnionRisk, equifaxRisk);
    const pastDueRiskStatus = Math.max(experianPastDueRisk, transUnionPastDueRisk, equifaxPastDueRisk);

    const experianUtilization = (Number(reportASummary?.availableCredit) || 0);
    const transUnionUtilization = (Number(reportBSummary?.availableCredit) || 0);
    const equifaxUtilization = (Number(reportCSummary?.availableCredit) || 0);

    const experianUtilRisk = experianUtilization < 60 ? RiskScale.SEVERE :
                             (experianUtilization >= 60 && experianUtilization <= 70) ? RiskScale.MILD : RiskScale.NONE;
                             
    const transUnionUtilRisk = transUnionUtilization < 60 ? RiskScale.SEVERE :
                             (transUnionUtilization >= 60 && transUnionUtilization <= 70) ? RiskScale.MILD : RiskScale.NONE;

    const equifaxUtilRisk = equifaxUtilization < 30 ? RiskScale.SEVERE :
                             (equifaxUtilization >= 30 && equifaxUtilization <= 40) ? RiskScale.MILD : RiskScale.NONE;

    const utilizationRiskStatus = Math.max(experianUtilRisk, transUnionUtilRisk, equifaxUtilRisk);

    const experianCreditMixRisk = (!reportASummary?.revolvingCredit && !reportASummary?.installmentCredit) ? RiskScale.SEVERE :
                            (!reportASummary?.revolvingCredit || !reportASummary?.installmentCredit) ? RiskScale.MILD : RiskScale.NONE;

    const transUnionCreditMixRisk = (!reportBSummary?.revolvingCredit && !reportBSummary?.installmentCredit) ? RiskScale.SEVERE :
                            (!reportBSummary?.revolvingCredit || !reportBSummary?.installmentCredit) ? RiskScale.MILD : RiskScale.NONE;

    const equifaxCreditMixRisk = (!reportCSummary?.revolvingCredit && !reportCSummary?.installmentCredit) ? RiskScale.SEVERE :
                            (!reportCSummary?.revolvingCredit || !reportCSummary?.installmentCredit) ? RiskScale.MILD : RiskScale.NONE;
    
    const creditMixRiskStatus = Math.max(experianCreditMixRisk, transUnionCreditMixRisk, equifaxCreditMixRisk);

    const inqueryRiskStatus = inqueries.length > 3 ? RiskScale.SEVERE : inqueries.length === 3 ? RiskScale.MILD : RiskScale.NONE;  
    
    return {
        scoreRisk: {
            itemized: [experianRisk, transUnionRisk, equifaxRisk],
            status: RiskScale[scoreRiskStatus]
        },
        pastDueRisk: {
            itemized: [experianPastDueRisk, transUnionPastDueRisk, equifaxPastDueRisk],
            status: RiskScale[pastDueRiskStatus]
        },
        utilizationRisk: {
            itemized: [experianUtilRisk, transUnionUtilRisk, equifaxUtilRisk],
            status: RiskScale[utilizationRiskStatus]
        },
        creditMixRisk: {
            itemized: [experianCreditMixRisk, transUnionCreditMixRisk, equifaxCreditMixRisk],
            status: RiskScale[creditMixRiskStatus]
        },
        inqueryRisk: {
            itemized: inqueries,
            status: RiskScale[inqueryRiskStatus]
        },
        overallRisk: Math.max(scoreRiskStatus, pastDueRiskStatus, utilizationRiskStatus, creditMixRiskStatus, inqueryRiskStatus)
    }
}