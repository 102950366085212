import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import PageTitle from "../../PageTitle";
import RadioGroup from "../../inputs/RadioGroup";

interface IGeneralInfoFormProps {
    register: UseFormRegister<any>;
    errors?: any;
}

const GeneralInfoForm: FunctionComponent<IGeneralInfoFormProps> = ({ register, errors }) => {
    const options = [
        {label: 'Yes', id: 'true'},
        {label: 'No', id: 'false'},
    ];

    return(
        <div className="py-10">
            <PageTitle title="General Information" />

            <div className="grid grid-cols-3 gap-4">
                <RadioGroup
                    question="Have you had to make unscheduled or emergency home repairs? "
                    name="homeRepairs"
                    register={register}
                    options={options}

                />

                <RadioGroup
                    question="Have you had any unplanned expenditures that have contributed to your situation?"
                    name="unplannedExpenditures"
                    register={register}
                    options={options}
                />
            </div>
        </div>
    )
}

export default GeneralInfoForm;