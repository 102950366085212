import { FunctionComponent, useEffect } from "react";
import DashboardPageContainer from "../../components/DashboardPageContainer";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/table";
import useManagers from "../../hooks/useManagers";
import { AddManagerModal } from "../../components/modals";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { ACCESS_LEVEL } from "../../constants";
import Loading from "../../components/Loading";
import { Manager } from "../../constants/managers";


const Managers: FunctionComponent = () => {
    const { user, loading } = useUser();
    const { userId } = user;    
    const { managers } = useManagers(userId);
    const navigate = useNavigate();

    const headerList = ['checkbox', 'name', 'email', 'phone', 'actions']
    const formattedManagers = managers?.map((manager: Manager) => {
        return {
            name: manager.userInfo.name,
            email: manager.userInfo.email,
            phone: manager.userInfo.phone
        }
    });

    useEffect(() => {
        if(user?.metadata?.accessLevel !== ACCESS_LEVEL.OWNER) navigate('/dashboard/clients')
    }, [])

    return (
        <DashboardPageContainer>
            <PageTitle title="Managers" />
            
            {
                loading ? <Loading />
                        : <Table
                            columnHeaders={headerList}
                            records={formattedManagers}
                            tableActions={[]}
                            customButton={<AddManagerModal />}
                            linkBase="/dashboard/managers"
                        />
            }
        </DashboardPageContainer>
    )
}

export default Managers;