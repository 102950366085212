import { StripeCardElementOptions } from '@stripe/stripe-js';

export enum ACCESS_LEVEL {
    OWNER = 0,
    CVA = 1,
    COMPANY = 10,
    MEMBER = 20,
    REFERRAL = 35,
    CLIENT = 50,
}

export enum CARD_BRANDS {
    VISA = 'visa',
    MASTERCARD = 'mastercard'
}

export enum PRODUCT_LIST_STAGE {
    SINGLE_PULL = 'prod_Qp9DvmyjaLLdTA',
    TRI_MERGE = 'prod_Qp9BMoi93yu2En',
    FIRST_APPOINTMENT = 'prod_Qp9GGUBN2oAnRV',
    FOLLOWUP_APPOINTMENT = 'prod_Qp9HrZoHWbdxYc'
}

export enum PRODUCT_LIST_PROD {
    SINGLE_PULL = 'prod_Qu58cEKXdzlK3B',
    TRI_MERGE = 'prod_Qu591CjfZljYNE',
    FIRST_APPOINTMENT = 'prod_RbeQL5Mqz9blvi',
    FOLLOWUP_APPOINTMENT = 'prod_RbeRO37Yfe217K'
}

export enum TABLE_COLUMN_TYPE {
    CHECKBOX = 'checkbox',
    ACTIONS = 'actions',
}

export enum UserTypes {
    MANAGER = 'Manager',
    COMPANY = 'Company',
    MEMBER = 'Member',
    CLIENT = 'Client',
    REFERRAL = 'Referrals',
    OWNER = 'Owner'
}

export enum RiskScale {
    'NONE' = 0,
    'MILD' = 1,
    'SEVERE' = 2
}

export const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
    disableLink: true
};

export enum ApplicationTypes {
    CLIENT = 'Client',
    SUBSCRIBER = 'Subscriber',
    PULL = 'Soft Credit Pull'
}

export enum APPLICATION_STATUS {
    REQUESTED = 'Requested',
    STARTED = 'Started',
    CONSENT_GIVEN = 'CONSENT GIVEN',
    CONSENT_DENIED = 'CONSENT_DENIED',
    COMPLETED = 'COMPLETED'
}