import { FunctionComponent, useEffect, useState } from "react";
import {
    Chat,
    Channel,
    ChannelList,
    Window,
    ChannelHeader,
    MessageList,
    MessageInput,
    Thread,
    useCreateChatClient,
  } from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
import { useUser } from "../../hooks/useUser";
import useClients from "../../hooks/useClients";

interface IMessengerProps {
    clientId: string;
}

const Messenger: FunctionComponent<IMessengerProps> = ({ clientId }) => {
    const apiKey = process.env.REACT_APP_STREAM_ACCESS_KEY || "";
    const { user, chatToken } = useUser();
    const { getClientById } = useClients()
    const filters = { members: { $in: [user?.userId, clientId] }, type: "messaging" };
    const options = { presence: true, state: true };
    const sort = { last_message_at: -1 };
    const client = getClientById(clientId);

    const [channel, setChannel] = useState<any>();

    const chatClient = useCreateChatClient({
        apiKey,
        tokenOrProvider: chatToken,
        userData: { id: user?.userId },
    });

    console.log(chatClient)
    console.log({userId: user?.userId, chatToken, apiKey})
    const fetchChannels = async (chatClient: any) => {
        const channelQueryResponse = await chatClient.queryChannels(
            filters
        );

        if(channelQueryResponse[0]) setChannel(channelQueryResponse[0])
        else {
            const newChannel = chatClient.channel("messaging", {
                name: `${user.userInfo.name} & ${client?.userInfo.name} `,
                members: [user.userId, client?.userId]
            });

            setChannel(newChannel)
        }
    }

    useEffect(() => {
        if(chatClient && !channel) fetchChannels(chatClient);
    }, [chatClient])

    if (!chatClient) return <div>Loading...</div>;

    return (
        <Chat 
            client={chatClient}
            customClasses={{
                channelList: "max-h-[300px]",
                channel: "max-h-[300px]",
            }}
        >
        <ChannelList filters={filters} options={options} />
        <Channel channel={channel}>
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    )
}

export default Messenger;