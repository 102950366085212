import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import PageTitle from "../../PageTitle";
import RadioGroup from "../../inputs/RadioGroup";

interface IEmploymentFormProps {
    register: UseFormRegister<any>;
    errors?: any;
}

const EmploymentForm: FunctionComponent<IEmploymentFormProps> = ({ register, errors }) => {
    const options = [
        {label: 'Yes', id: 'true'},
        {label: 'No', id: 'false'},
    ];

    return (
        <div className="py-10">
            <PageTitle title="Employment Information" />

            <div className="grid grid-cols-3 gap-4">
                <RadioGroup
                    question="Have your work hours decreased?"
                    name="decreasedWorkHours"
                    register={register}
                    options={options}

                />

                <RadioGroup
                    question="Have you been made redundant?"
                    name="madeRedundant"
                    register={register}
                    options={options}
                />

                <RadioGroup
                    question="Are you unable to work due to injury or illness?"
                    name="injuryOrIllness"
                    register={register}
                    options={options}
                />
            </div>
        </div>
    )
}

export default EmploymentForm;