import { FunctionComponent } from "react";


interface ISubMenuItemProps {
    text: string;
    author: string;
    read: boolean;
    date: string;
}

const SubMenuItem: FunctionComponent<ISubMenuItemProps> = ({text, author, date, read}) => (
    <div className={`border border-primary border-r-0 border-l-0 border-t-0 w-full p-4 relative ${read ? 'bg-secondary-light' : ''}`}>
        <div className="flex justify-between items-center">
            <p className="text-xs font-semibold">{author}</p>
            <p className="text-xs font-semibold">{date}</p>
        </div>
        <p className="mt-1.5 truncate max-w-[300px]">
            {text}
        </p>
    </div>
);

export default SubMenuItem;