import { useState, FunctionComponent, useEffect } from "react";
import SlimTile from "../tiles/SlimTile"
import { stripeHelpers } from "../../utils/ClientVitalsAPI";
import { useUser } from "../../hooks/useUser";
import AddSubscriptionModal from "../modals/AddSubscriptionModal";


const SubscriptionOptions: FunctionComponent = () => {
    const [products, setProducts] = useState<any[]>([]);

    const { user } = useUser();
    // @ts-ignore
    const stripeId = user.metadata?.stripeId

    const fetchProductCatalogue = async () => {
        try {
            const result = await stripeHelpers.fetchStripeCustomerSubscriptions(stripeId);
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if(products.length < 1) fetchProductCatalogue()
    }, [user])

    return (
        <SlimTile>
            <h5 className="uppercase font-medium text-md text-[#AEA8A8] my-3">
                Subscription
            </h5>

            {
                (products.length > 0) ? products.map(({name, description, prices}) => (
                        <div className="border border-secondary rounded-sm h-[130px] flex align-start justify-between py-3 px-5">
                            <p>{name}</p>
                            <p>${(prices[0]?.unit_amount / 100).toFixed(2)} / MO</p>
                        </div>
                    ))
                    : (
                        <div className="border border-secondary rounded-sm h-[130px] flex flex-col align-center justify-around py-3 px-5">
                            <p className="text-center">No Subscriptions Available for Account</p>
                            
                            <div className="mx-auto">
                                <AddSubscriptionModal />
                            </div>
                        </div>
                    )
            }
        </SlimTile>
    )
}

export default SubscriptionOptions;