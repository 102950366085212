import axios from "axios";
import { IClientInvitationForm, Client } from "../../constants/clients";
import { UserTypes, ACCESS_LEVEL } from "../../constants";

const BASE_URL = process.env.REACT_APP_CLIENTVITALS_API;

const formatClient: (data: IClientInvitationForm, companyId: string, memberId?: string) => Client = (data, companyId, memberId) => {
    return {
        userId: '',
        userType: UserTypes.CLIENT,
        userInfo: {
            name: data.name.replace('-', ' '),
            address: data.address,
            city: data.city,
            state: data.state,
            zip: data.zip,
            phone: data.phone,
            country: '',
            email: data.email.toLowerCase(),
            companyId: companyId,
            memberId: memberId || '',
            dob: data.birthdate,
        },
        metadata: {
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString(),
            firstLogin: true,
            browserUsed: 'chrome', 
            reApply: false,
            accessLevel: ACCESS_LEVEL.CLIENT
        },
    }
}

const getAllClients = async () => {
    try {
        const result = await axios.get(`${BASE_URL}/clients/all`);
        //@ts-ignore
        const clients = result?.data?.result?.Items?.sort((a: any, b: any) => new Date(b.metadata.createdAt) - new Date(a.metadata.createdAt))
        
        return clients
    } catch (error) {
        throw error;
    }
}

const getCompanyClients = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/clients/company/${id}`);
        //@ts-ignore
        const clients = result?.data?.result?.Items?.sort((a: any, b: any) => new Date(b.metadata.createdAt) - new Date(a.metadata.createdAt))
        
        return clients
    } catch (error) {
        throw error;
    }
}

const getMemberClients = async (id: string) => {
    try {
        const link = `${BASE_URL}/clients/member/${id}`
       
        const result = await axios.get(link);
        //@ts-ignore
        const clients = result?.data?.result?.Items?.sort((a: any, b: any) => new Date(b.metadata.createdAt) - new Date(a.metadata.createdAt))

        return clients
    } catch (error) {
        throw error;
    }
}

const addClient = async (client: Client) => {
    try {
        const result = await axios.post(`${BASE_URL}/clients/add`, client);

        return result
    } catch (error) {
        throw error;
    }
}

const updateClient = async (clientData: Client) => {
    try {
        const link = `${BASE_URL}/clients/update`;
       
        const result = await axios.put(link, clientData);

        return result
    } catch (error) {
        throw error;
    }
}

const archiveClient = async (clientId: string) => {
    try {
        const link = `${BASE_URL}/clients/archive`;
       
        const result = await axios.put(link, {clientId});

        return result
    } catch (error) {
        throw error;
    }
}

const fetchClientById = async (id: string) => {
    try {
        const result = await axios.get(`${BASE_URL}/clients/single/${id}`);

        return result?.data?.result?.Items[0]
    } catch (error) {
        throw error;
    }
}

export default {
    getAllClients,
    getCompanyClients,
    getMemberClients,
    addClient,
    formatClient,
    updateClient,
    archiveClient,
    fetchClientById
}