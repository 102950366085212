import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";

interface IRadioGroupProps {
    options: { id: string; label: string }[];
    name: string;
    selectedOption?: string;
    containerStyles?: string;
    labelTextStyles?: string;
    onChange?: (value: string) => void;
    register?: UseFormRegister<any>
    question?: string;
  }

const RadioGroup: FunctionComponent<IRadioGroupProps> = ({ options, name, selectedOption, containerStyles, labelTextStyles, question, onChange, register }) => (
    !register ? (
        <div className={`${containerStyles}`}>
            {options.map((option) => (
                <label key={option.id} className="flex justify-end items-center cursor-pointer">
                <input
                    type="radio"
                    name={name}
                    value={option.id}
                    checked={selectedOption === option.id}
                    onChange={onChange ? (e) => onChange(e.target.value) : () => { }}
                    className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out focus:ring focus:ring-offset-1 focus:ring-blue-500"
                />
                <span className={`text-gray-800 ${labelTextStyles}`}>{option.label}</span>
                </label>
            ))}
        </div>
    )
    : (
    <div className={`space-y-2 ${containerStyles}`}>
        {question ? <p className="font-semibold">{question}</p> : <></>}
        <div className="flex">
        {options.map((option) => (
          <label key={option.id} className="flex items-center space-x-3 cursor-pointer mr-8">
            <input
              type="radio"
              {...register(name)}  // Use register to bind the input
              value={option.id}
              className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out focus:ring focus:ring-offset-1 focus:ring-blue-500"
            />
            <span className="text-gray-800">{option.label}</span>
          </label>
        ))}
        </div>
      </div>
    )
);

export default RadioGroup;