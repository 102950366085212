import { FunctionComponent } from "react";
import StepCircle from "./StepCircle";
import FillerCircle from "./FillerCircle";

interface IStepperProps {
    currentStep: number;
    totalSteps: number;
}

const Stepper: FunctionComponent<IStepperProps> = ({ currentStep, totalSteps }) => {
    const stepElements = [];

    for(let i = 1; i <= totalSteps; i++) {
        if(i !== totalSteps) stepElements.push(<StepCircle key={i} completed={i <= currentStep} step={i} />, <FillerCircle key={i + 'filler'} completed={i+1 <= currentStep} />, <FillerCircle key={i + 'filler-2'} completed={i+1 <= currentStep} />);
        else stepElements.push(<StepCircle key={i} completed={i <= currentStep} step={i} />)
    }

    return (
        <div className="flex items-center">
           {stepElements} 
        </div>
    )
}

export default Stepper;