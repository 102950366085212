import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import PageTitle from "../../PageTitle";
import RadioGroup from "../../inputs/RadioGroup";


interface IHousingFormProps {
    register: UseFormRegister<any>;
    errors?: any;
}

const HousingForm: FunctionComponent<IHousingFormProps> = ({ register, errors }) => {
    const options = [
        {label: 'Yes', id: 'true'},
        {label: 'No', id: 'false'},
    ];

    return (
        <div className="py-10">
            <PageTitle title="Housing Information" />

            <div className="grid grid-cols-3 gap-2">
                <RadioGroup
                    question="Have you been denied a mortgage?"
                    name="deniedMortgage"
                    register={register}
                    options={options}

                />

                <RadioGroup
                    question="Have you been denied a rental?"
                    name="deniedRental"
                    register={register}
                    options={options}
                />

                <RadioGroup
                    question="Have you defaulted on a mortgage or been unable to pay your rent?"
                    name="defaulted"
                    register={register}
                    options={options}

                />
            </div>
        </div>
    )
}

export default HousingForm;